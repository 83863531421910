
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
// import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from 'moment';
import { Checkbox } from "@mui/material";
import Select from 'react-select';
import * as service from '../../../services/service';

function AddGroupBooking() {
    const [state, setState] = useState(
        {
            leaderfirstname: '', leadermiddlename: '', leaderlastname: '', leadercenter: '', emailid: '', mobileno: '', registrationType: '', leaderpan: '', utrCode: '',
            firstname1: '', middlename1: '', lastname1: '', center1: '', pan1: '', contribution1: '0', coupono1: '',
            firstname2: '', middlename2: '', lastname2: '', center2: '', pan2: '', contribution2: '0', coupono2: '',
            firstname3: '', middlename3: '', lastname3: '', center3: '', pan3: '', contribution3: '0', coupono3: '',
            firstname4: '', middlename4: '', lastname4: '', center4: '', pan4: '', contribution4: '0', coupono4: '',
            firstname5: '', middlename5: '', lastname5: '', center5: '', pan5: '', contribution5: '0', coupono5: '',
            firstname6: '', middlename6: '', lastname6: '', center6: '', pan6: '', contribution6: '0', coupono6: '',
            firstname7: '', middlename7: '', lastname7: '', center7: '', pan7: '', contribution7: '0', coupono7: '',
            firstname8: '', middlename8: '', lastname8: '', center8: '', pan8: '', contribution8: '0', coupono8: '',
            firstname9: '', middlename9: '', lastname9: '', center9: '', pan9: '', contribution9: '0', coupono9: '',
            firstname10: '', middlename10: '', lastname10: '', center10: '', pan10: '', contribution10: '0', coupono10: '',
        });
    const [countryId, setCountryId] = useState('');
    const [countryData, setCountryData] = useState([]);
    const [stateId, setStateId] = useState('');
    const [stateData, setStateData] = useState([]);
    const [districtId, setDistrictId] = useState('');
    const [districtData, setDistrictData] = useState([]);
    const [donationTypeId, setDonationTypeId] = useState('');
    const [donationTypeData, setDonationTypeData] = useState([]);
    const [bankId, setBankId] = useState('');
    const [bankData, setBankData] = useState([]);
    const [groupLeaderId, setGroupLeaderId] = useState('');
    const [GroupLeaderData, setGroupLeaderData] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [leaderGender, setLeaderGender] = useState('');
    const [gender1, setGender1] = useState('');
    const [donorType1, setDonorType1] = useState('');
    const [seniorCitizen1, setSeniorCitizen1] = useState(false);
    const [gender2, setGender2] = useState('');
    const [donorType2, setDonorType2] = useState('');
    const [seniorCitizen2, setSeniorCitizen2] = useState(false);
    const [gender3, setGender3] = useState('');
    const [donorType3, setDonorType3] = useState('');
    const [seniorCitizen3, setSeniorCitizen3] = useState(false);
    const [gender4, setGender4] = useState('');
    const [donorType4, setDonorType4] = useState('');
    const [seniorCitizen4, setSeniorCitizen4] = useState(false);
    const [gender5, setGender5] = useState('');
    const [donorType5, setDonorType5] = useState('');
    const [seniorCitizen5, setSeniorCitizen5] = useState(false);
    const [gender6, setGender6] = useState('');
    const [donorType6, setDonorType6] = useState('');
    const [seniorCitizen6, setSeniorCitizen6] = useState(false);
    const [gender7, setGender7] = useState('');
    const [donorType7, setDonorType7] = useState('');
    const [seniorCitizen7, setSeniorCitizen7] = useState(false);
    const [gender8, setGender8] = useState('');
    const [donorType8, setDonorType8] = useState('');
    const [seniorCitizen8, setSeniorCitizen8] = useState(false);
    const [gender9, setGender9] = useState('');
    const [donorType9, setDonorType9] = useState('');
    const [seniorCitizen9, setSeniorCitizen9] = useState(false);
    const [gender10, setGender10] = useState('');
    const [donorType10, setDonorType10] = useState('');
    const [seniorCitizen10, setSeniorCitizen10] = useState(false);
    const [currency, setCurrency] = useState('');
    const [paymentMode, setPaymentMode] = useState('');
    const [paymentDate, setPaymentDate] = useState(new Date());
    const genderData = [{ label: "Male", value: "Male" }, { label: "Female", value: "Female" }];
    const donorTypeData = [{ label: "Adult", value: "Adult" }, { label: "Yuva", value: "Yuvashakti" }, { label: "Bal", value: "Balshakti" }];
    const currencyData = [{ label: "INR", value: "INR" }];
    const paymentModeData = [{ label: "Cash", value: "Cash" }, { label: "Bank Transfer", value: "Bank Transfer" },
    { label: "QR Code", value: "QR Code" },];
    const [adultContri, setAdultContri] = useState('');
    const [yuvaContri, setYuvaContri] = useState('');
    const [balContri, setBalContri] = useState('');
    const [contribution, setContribution] = useState(0);
    const [stateUser, setStateUser] = useState(false);
    const [districtUser, setDistrictUser] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const useremail = JSON.parse(sessionStorage.getItem('userData')).emailId;
    // const navigate = useNavigate();
    // const params = useParams();
    const getGroupLeaders = (stId, dtId) => {
        fetch(
            service.GROUP_LEADERS_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    StateId: stId,
                    DistrictId: dtId
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.groupLeaderId, label: item.firstName.concat(" ", item.middleName).concat(" ", item.lastName) }));
                setGroupLeaderData(data2);
            });
    };

    const getDonationTypes = () => {
        fetch(
            service.DONATION_TYPE_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.donationTypeId, label: item.name }));
                setDonationTypeData(data2);
                // assuming single database per puja
                setAdultContri(result[0].adultContri);
                setYuvaContri(result[0].yuvaContri);
                setBalContri(result[0].balContri);

            });
    };

    const getCountries = () => {
        fetch(
            service.COUNTRY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.countryId, label: item.country }));
                setCountryData(data2);
            });
    };
    const getStates = (id) => {
        fetch(
            service.GET_STATE_BY_COUNTRY_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CountryId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.stateId, label: item.state }));
                setStateData(data2);

                if (JSON.parse(sessionStorage.getItem('userData')).userRole !== 'Global') {
                    const st = data2.find(x => x.value === JSON.parse(sessionStorage.getItem('userData')).stateId);
                    setStateId(st);
                }
            });
    };

    const getDistricts = (id) => {
        fetch(
            service.GET_DISTRICT_BY_STATE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    StateId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.districtId, label: item.district }));
                setDistrictData(data2);

                if (JSON.parse(sessionStorage.getItem('userData')).userRole !== 'Global') {
                    const dt = data2.find(x => x.value === JSON.parse(sessionStorage.getItem('userData')).districtId);
                    setDistrictId(dt);
                }
            });
    };
    const getBanks = () => {
        fetch(
            service.BANK_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.bankId, label: item.bank }));
                setBankData(data2);
            });
    };
    const getGroupLeaderDetailsById = (id) => {
        setLoading(true);
        const errs = {};
        fetch(
            service.GET_GROUP_LEADER_BY_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    GroupLeaderId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.message.messageType === 'Success') {
                    setState({
                        ...state, leaderfirstname: result.firstName, leadermiddlename: result.middleName, leaderlastname: result.lastName,
                        leadercenter: result.center,
                        emailid: result.emailId, mobileno: result.mobileNo, leaderpan: result.pan
                    });

                    const conData = ({ value: result.countryId, label: result.country });
                    setCountryId(conData);
                    getStates(conData.value);
                    const stData = ({ value: result.stateId, label: result.state });
                    setStateId(stData);
                    getDistricts(stData.value);
                    const disData = ({ value: result.districtId, label: result.district });
                    setDistrictId(disData);

                    const gen = ({ value: result.gender, label: result.gender });
                    setLeaderGender(gen);
                }
                else {
                    errs[".invalid"] = result.message.message;
                    setErrors(errs);
                }
            });
        setLoading(false);
    };

    useEffect(() => {
        getBanks();
        getCountries();
        getDonationTypes();
        const def = { value: 1, label: 'India' };
        setCountryId(def);
        getStates(def.value);
        if (JSON.parse(sessionStorage.getItem('userData')).userRole !== 'Global') {
            getDistricts(JSON.parse(sessionStorage.getItem('userData')).stateId);
        }

        if (JSON.parse(sessionStorage.getItem('userData')).userRole === 'State') {
            setStateUser(true);
        }
        else if (JSON.parse(sessionStorage.getItem('userData')).userRole === 'District') {
            setDistrictUser(true);
        }

        getGroupLeaders(JSON.parse(sessionStorage.getItem('userData')).stateId, JSON.parse(sessionStorage.getItem('userData')).districtId);

        const cur = { label: 'INR', value: 'INR' };
        setCurrency(cur);

        const mode = { label: 'Cash', value: 'Cash' };
        setPaymentMode(mode);

        const gen = { label: 'Male', value: 'Male' };
        setLeaderGender(gen);
        setGender1(gen);
        setGender2(gen);
        setGender3(gen);
        setGender4(gen);
        setGender5(gen);
        setGender6(gen);
        setGender7(gen);
        setGender8(gen);
        setGender9(gen);
        setGender10(gen);

        const type = { value: 1, label: 'Diwali Puja 2024' };
        setDonationTypeId(type);

    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!state.firstname1) {
            isValid = false;
            errs[".firstname1"] = "Enter first name";
        }
        if (!state.lastname1) {
            isValid = false;
            errs[".lastname1"] = "Enter last name";
        }
        if (!donorType1) {
            isValid = false;
            errs[".donorType1"] = "Select type";
        }
        if (!state.pan1) {
            isValid = false;
            errs[".pan1"] = "Enter PAN";
        }
        if (!groupLeaderId && !state.leaderfirstname) {
            isValid = false;
            errs[".leaderfirstname"] = "Please enter group leader first name";
        }
        if (!groupLeaderId && !state.leaderlastname) {
            isValid = false;
            errs[".leaderlastname"] = "Please enter group leader last name";
        }
        if (!groupLeaderId && !stateId) {
            isValid = false;
            errs[".state"] = "Please select state";
        }
        if (!groupLeaderId && !districtId) {
            isValid = false;
            errs[".district"] = "Please select district";
        }
        if (!groupLeaderId && !state.emailid) {
            isValid = false;
            errs[".emailId"] = "Please enter emailId";
        }
        if (!groupLeaderId && !state.mobileno) {
            isValid = false;
            errs[".mobileno"] = "Please enter mobile no.";
        }
        if (!groupLeaderId && !state.leaderpan) {
            isValid = false;
            errs[".leaderpan"] = "Please enter PAN";
        }
        if (!contribution) {
            isValid = false;
            errs[".contribution"] = "Please enter contribution";
        }
        if (paymentMode.value === "Bank Transfer" && !bankId) {
            isValid = false;
            errs[".bank"] = "Please select bank";
        }
        if (paymentMode.value === "Bank Transfer" && !state.utrCode) {
            isValid = false;
            errs[".utrCode"] = "Please enter UTRCode";
        }
        // if (state.emailid !== "undefined") {
        //     const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        //     if (!pattern.test(state.emailid)) {
        //       isValid = false;
        //       errs[".emailid"] = "Please enter valid email address";
        //     }
        //   }
        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const countryChangeHandler = (ev) => {
        setCountryId(ev);
        setStateId(null);
        setDistrictId(null);
        getStates(ev.value);
    }
    const stateChangeHandler = (ev) => {
        setStateId(ev);
        setDistrictId(null);
        getDistricts(ev.value);
    }
    const districtChangeHandler = (ev) => {
        setDistrictId(ev);
    }
    const donationChangeHandler = (ev) => {
        setDonationTypeId(ev);
    }
    const bankChangeHandler = (ev) => {
        setBankId(ev);
    }

    function GetContribution(val) {
        let contri = 0;
        if (val === "Adult") {
            contri = adultContri;
        }
        else if (val === "Yuvashakti") {
            contri = yuvaContri;
        }
        else if (val === "Balshakti") {
            contri = balContri;
        }
        return contri;
    }

    const donorType1ChangeHandler = (ev) => {
        const contri = GetContribution(ev.value);
        state.contribution1 = contri;

        const contrib = contri + parseInt(state.contribution2, 10) + parseInt(state.contribution3, 10) + parseInt(state.contribution4, 10)
            + parseInt(state.contribution5, 10) + parseInt(state.contribution6, 10) + parseInt(state.contribution7, 10)
            + parseInt(state.contribution8, 10) + parseInt(state.contribution9, 10) + parseInt(state.contribution10, 10);
        setContribution(contrib);
        setDonorType1(ev);
    }

    const donorType2ChangeHandler = (ev) => {
        const contri = GetContribution(ev.value);
        state.contribution2 = contri;

        const contrib = parseInt(state.contribution1, 10) + contri + parseInt(state.contribution3, 10) + parseInt(state.contribution4, 10)
            + parseInt(state.contribution5, 10) + parseInt(state.contribution6, 10) + parseInt(state.contribution7, 10)
            + parseInt(state.contribution8, 10) + parseInt(state.contribution9, 10) + parseInt(state.contribution10, 10);
        setContribution(contrib);
        setDonorType2(ev);
    }

    const donorType3ChangeHandler = (ev) => {
        const contri = GetContribution(ev.value);
        state.contribution3 = contri;

        const contrib = parseInt(state.contribution1, 10) + parseInt(state.contribution2, 10) + contri + parseInt(state.contribution4, 10) + parseInt(state.contribution5, 10)
            + parseInt(state.contribution6, 10) + parseInt(state.contribution7, 10) + parseInt(state.contribution8, 10) + parseInt(state.contribution9, 10) + parseInt(state.contribution10, 10);
        setContribution(contrib);
        setDonorType3(ev);
    }

    const donorType4ChangeHandler = (ev) => {
        const contri = GetContribution(ev.value);
        state.contribution4 = contri;

        const contrib = parseInt(state.contribution1, 10) + parseInt(state.contribution2, 10) + parseInt(state.contribution3, 10)
            + contri + parseInt(state.contribution5, 10) + parseInt(state.contribution6, 10) + parseInt(state.contribution7, 10)
            + parseInt(state.contribution8, 10) + parseInt(state.contribution9, 10) + parseInt(state.contribution10, 10);
        setContribution(contrib);
        setDonorType4(ev);
    }
    const donorType5ChangeHandler = (ev) => {
        const contri = GetContribution(ev.value);
        state.contribution5 = contri;

        const contrib = parseInt(state.contribution1, 10) + parseInt(state.contribution2, 10) + parseInt(state.contribution3, 10) + parseInt(state.contribution4, 10) + contri
            + parseInt(state.contribution6, 10) + parseInt(state.contribution7, 10) + parseInt(state.contribution8, 10)
            + parseInt(state.contribution9, 10) + parseInt(state.contribution10, 10);
        setContribution(contrib);
        setDonorType5(ev);
    }
    const donorType6ChangeHandler = (ev) => {
        const contri = GetContribution(ev.value);
        state.contribution6 = contri;

        const contrib = parseInt(state.contribution1, 10) + parseInt(state.contribution2, 10) + parseInt(state.contribution3, 10) + parseInt(state.contribution4, 10)
            + parseInt(state.contribution5, 10) + contri + parseInt(state.contribution7, 10) + parseInt(state.contribution8, 10)
            + parseInt(state.contribution9, 10) + parseInt(state.contribution10, 10);
        setContribution(contrib);
        setDonorType6(ev);
    }
    const donorType7ChangeHandler = (ev) => {
        const contri = GetContribution(ev.value);
        state.contribution7 = contri;

        const contrib = parseInt(state.contribution1, 10) + parseInt(state.contribution2, 10) + parseInt(state.contribution3, 10) + parseInt(state.contribution4, 10)
            + parseInt(state.contribution5, 10) + parseInt(state.contribution6, 10) + contri + parseInt(state.contribution8, 10)
            + parseInt(state.contribution9, 10) + parseInt(state.contribution10, 10);
        setContribution(contrib);
        setDonorType7(ev);
    }
    const donorType8ChangeHandler = (ev) => {
        const contri = GetContribution(ev.value);
        state.contribution8 = contri;

        const contrib = parseInt(state.contribution1, 10) + parseInt(state.contribution2, 10) + parseInt(state.contribution3, 10) + parseInt(state.contribution4, 10)
            + parseInt(state.contribution5, 10) + parseInt(state.contribution6, 10) + parseInt(state.contribution7, 10)
            + contri + parseInt(state.contribution9, 10) + parseInt(state.contribution10, 10);
        setContribution(contrib);
        setDonorType8(ev);
    }
    const donorType9ChangeHandler = (ev) => {
        const contri = GetContribution(ev.value);
        state.contribution9 = contri;

        const contrib = parseInt(state.contribution1, 10) + parseInt(state.contribution2, 10) + parseInt(state.contribution3, 10)
            + parseInt(state.contribution4, 10) + parseInt(state.contribution5, 10) + parseInt(state.contribution6, 10)
            + parseInt(state.contribution7, 10) + parseInt(state.contribution8, 10) + contri + parseInt(state.contribution10, 10);
        setContribution(contrib);
        setDonorType9(ev);
    }
    const donorType10ChangeHandler = (ev) => {
        const contri = GetContribution(ev.value);
        state.contribution10 = contri;

        const contrib = parseInt(state.contribution1, 10) + parseInt(state.contribution2, 10) + parseInt(state.contribution3, 10)
            + parseInt(state.contribution4, 10) + parseInt(state.contribution5, 10) + parseInt(state.contribution6, 10)
            + parseInt(state.contribution7, 10) + parseInt(state.contribution8, 10) + parseInt(state.contribution9, 10) + contri;
        setContribution(contrib);
        setDonorType10(ev);
    }

    const seniorCitizen1ChangeHandler = (ev) => {
        setSeniorCitizen1(ev.target.checked);
    }
    const seniorCitizen2ChangeHandler = (ev) => {
        setSeniorCitizen2(ev.target.checked);
    }
    const seniorCitizen3ChangeHandler = (ev) => {
        setSeniorCitizen3(ev.target.checked);
    }
    const seniorCitizen4ChangeHandler = (ev) => {
        setSeniorCitizen4(ev.target.checked);
    }
    const seniorCitizen5ChangeHandler = (ev) => {
        setSeniorCitizen5(ev.target.checked);
    }
    const seniorCitizen6ChangeHandler = (ev) => {
        setSeniorCitizen6(ev.target.checked);
    }
    const seniorCitizen7ChangeHandler = (ev) => {
        setSeniorCitizen7(ev.target.checked);
    }
    const seniorCitizen8ChangeHandler = (ev) => {
        setSeniorCitizen8(ev.target.checked);
    }
    const seniorCitizen9ChangeHandler = (ev) => {
        setSeniorCitizen9(ev.target.checked);
    }
    const seniorCitizen10ChangeHandler = (ev) => {
        setSeniorCitizen10(ev.target.checked);
    }
    const groupLeaderChangeHandler = (ev) => {
        setGroupLeaderId(ev);
        getGroupLeaderDetailsById(ev.value);
        // readonly controls
    }

    function AddDonor(groupLeadId, firstName, middleName, lastName, cntryId, statId, distrId, cntr, gndr, pan, dnrType, senior, paymentId, contri, coupnno) {
        fetch(
            service.ADD_UPDATE_DONOR,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    DonorId: 0,
                    GroupLeaderId: groupLeadId,
                    FirstName: firstName,
                    MiddleName: middleName,
                    LastName: lastName,
                    CountryId: cntryId,
                    StateId: statId,
                    DistrictId: distrId,
                    Center: cntr,
                    Gender: gndr,
                    PAN: pan,
                    DonorType: dnrType,
                    IsSeniorCitizen: senior,
                    IsAccomodationRequired: true,
                    PaymentId: paymentId,
                    Contribution: contri,
                    CouponNo: coupnno,
                    AddedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                    UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.message.messageType === 'Failed') {
                    // eslint-disable-next-line
                    alert(result.message.message);
                }
            });
    }

    function AddDonors(grpLeadId, paymentId) {
        // Add donors 1
        AddDonor(grpLeadId, state.firstname1, state.middlename1, state.lastname1, countryId.value, stateId.value, districtId.value,
            state.center1, gender1.value, state.pan1, donorType1.value, seniorCitizen1, paymentId, state.contribution1, state.coupono1)
        // Add donors 2
        if (state.firstname2 !== "" && state.lastname2 !== "" && state.contribution2 !== "") {
            AddDonor(grpLeadId, state.firstname2, state.middlename2, state.lastname2, countryId.value, stateId.value, districtId.value,
                state.center2, gender2.value, state.pan2, donorType2.value, seniorCitizen2, paymentId, state.contribution2, state.coupono2)
        }
        // Add donors 3
        if (state.firstname3 !== "" && state.lastname3 !== "" && state.contribution3 !== "") {
            AddDonor(grpLeadId, state.firstname3, state.middlename3, state.lastname3, countryId.value, stateId.value, districtId.value,
                state.center3, gender3.value, state.pan3, donorType3.value, seniorCitizen3, paymentId, state.contribution3, state.coupono3)
        }
        // Add donors 4
        if (state.firstname4 !== "" && state.lastname4 !== "" && state.contribution4 !== "") {
            AddDonor(grpLeadId, state.firstname4, state.middlename4, state.lastname4, countryId.value, stateId.value, districtId.value,
                state.center4, gender4.value, state.pan4, donorType4.value, seniorCitizen4, paymentId, state.contribution4, state.coupono4)
        }
        // Add donors 5
        if (state.firstname5 !== "" && state.lastname5 !== "" && state.contribution5 !== "") {
            AddDonor(grpLeadId, state.firstname5, state.middlename5, state.lastname5, countryId.value, stateId.value, districtId.value,
                state.center5, gender5.value, state.pan5, donorType5.value, seniorCitizen5, paymentId, state.contribution5, state.coupono5)
        }
        // Add donors 6
        if (state.firstname6 !== "" && state.lastname6 !== "" && state.contribution6 !== "") {
            AddDonor(grpLeadId, state.firstname6, state.middlename6, state.lastname6, countryId.value, stateId.value, districtId.value,
                state.center6, gender6.value, state.pan6, donorType6.value, seniorCitizen6, paymentId, state.contribution6, state.coupono6)
        }
        // Add donors 7
        if (state.firstname7 !== "" && state.lastname7 !== "" && state.contribution7 !== "") {
            AddDonor(grpLeadId, state.firstname7, state.middlename7, state.lastname7, countryId.value, stateId.value, districtId.value,
                state.center7, gender7.value, state.pan7, donorType7.value, seniorCitizen7, paymentId, state.contribution7, state.coupono7)
        }
        // Add donors 8
        if (state.firstname8 !== "" && state.lastname8 !== "" && state.lastname8 !== "" && state.contribution8 !== "") {
            AddDonor(grpLeadId, state.firstname8, state.middlename8, state.lastname8, countryId.value, stateId.value, districtId.value,
                state.center8, gender8.value, state.pan8, donorType8.value, seniorCitizen8, paymentId, state.contribution8, state.coupono8)
        }
        // Add donors 9
        if (state.firstname9 !== "" && state.lastname9 !== "" && state.contribution9 !== "") {
            AddDonor(grpLeadId, state.firstname9, state.middlename9, state.lastname9, countryId.value, stateId.value, districtId.value,
                state.center9, gender9.value, state.pan9, donorType9.value, seniorCitizen9, paymentId, state.contribution9, state.coupono9)
        }
        // Add donors 10
        if (state.firstname10 !== "" && state.lastname10 !== "" && state.contribution10 !== "") {
            AddDonor(grpLeadId, state.firstname10, state.middlename10, state.lastname10, countryId.value, stateId.value, districtId.value,
                state.center10, gender10.value, state.pan10, donorType10.value, seniorCitizen10, paymentId, state.contribution10, state.coupono10)
        }
        // eslint-disable-next-line
        alert("Data Added Successfully.");
        window.location.reload();
    }
    function AddPayment(grpLeadId) {
        const errs = {};
        fetch(
            service.ADD_DONOR_PAYMENT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    GroupLeaderId: grpLeadId,
                    DonationTypeId: donationTypeId.value,
                    Contribution: contribution,
                    Currency: currency.value,
                    PaymentMode: paymentMode.value,
                    PaymentDate: moment(paymentDate).format("MM/DD/YYYY"),
                    UTRCode: state.utrCode,
                    BankId: bankId.value,
                    AddedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                    UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                })
            })
            .then(res => res.json())
            .then(resultP => {
                if (resultP.message.messageType === 'Success') {
                    AddDonors(grpLeadId, resultP.paymentId);
                }
                else if (resultP.messageType === 'Failed') {
                    // eslint-disable-next-line
                    alert(resultP.message);
                }
                else {
                    errs[".invalid"] = resultP.message;
                    setErrors(errs);
                }
            });
    }

    function AddGroupLeader() {
        fetch(
            service.ADD_UPDATE_GROUP_LEADER,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    GroupLeaderId: 0,
                    FirstName: state.leaderfirstname,
                    MiddleName: state.leadermiddlename,
                    LastName: state.leaderlastname,
                    CountryId: countryId.value,
                    StateId: stateId.value,
                    DistrictId: districtId.value,
                    Center: state.leadercenter,
                    MobileNo: state.mobileno,
                    EmailId: state.emailid,
                    Gender: leaderGender.value,
                    PAN: state.leaderpan,
                    RegistrationType: 'GroupLeaderByAdmin', // IndividualByAdmin, GroupLeaderBySelf, IndividualBySelf 
                    AddedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                    UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.message.messageType === 'Success') {
                    AddPayment(result.groupLeaderId);
                }
                else if (result.message.messageType === 'Failed') {
                    // eslint-disable-next-line
                    alert(result.message.message);
                }
            });
    }


    const submitHandler = e => {
        e.preventDefault();
        setLoading(true);
        if (validate()) {
            // Add new group leader
            if (!groupLeaderId) {
                AddGroupLeader();
            }
            else {
                AddPayment(groupLeaderId.value);
            }
        }
        setLoading(false);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {useremail === 't@t.com' || useremail === 'a@a.com' ?
                <MDBox pt={6} pb={3}>
                    <div style={{ fontSize: '14px', fontWeight: 'bold', height: '500px', color: 'red' }} align='center'>
                        Access Denied
                    </div>
                </MDBox>
                :
                <MDBox pt={6} pb={3}>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                >
                                    <MDTypography variant="h6" color="white">
                                        Save Details
                                    </MDTypography>
                                </MDBox>
                                <Card style={{ alignItems: 'center' }}>
                                    <br />
                                    <TableContainer component={Paper} style={{ width: '98%', alignSelf: 'center', height: '1600px' }}>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>
                                                        <Dropdown menuClassName='myMenuClassName' id="groupLeader"
                                                            name="groupLeader"
                                                            options={GroupLeaderData}
                                                            value={groupLeaderId}
                                                            onChange={groupLeaderChangeHandler}
                                                            label="Select Group Leaders"
                                                            placeholder="Select Group Leader"
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>New Group Leader</div>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{ width: '35%' }}>
                                                        <MDInput label="First Name" fullWidth value={state.leaderfirstname || ''}
                                                            name="leaderfirstname"
                                                            onChange={changeHandler}
                                                            id="leaderfirstname"
                                                            disabled={groupLeaderId}
                                                            required />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".leaderfirstname"]}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <MDInput label="Middle Name" fullWidth value={state.leadermiddlename || ''}
                                                            name="leadermiddlename"
                                                            onChange={changeHandler}
                                                            id="leadermiddlename"
                                                            disabled={groupLeaderId}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".leadermiddlename"]}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <MDInput label="Last Name" fullWidth value={state.leaderlastname || ''}
                                                            name="leaderlastname"
                                                            onChange={changeHandler}
                                                            id="leaderlastname"
                                                            disabled={groupLeaderId}
                                                            required />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".leaderlastname"]}</div>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <Dropdown menuClassName='myMenuClassName' id="country"
                                                            name="country"
                                                            options={countryData}
                                                            value={countryId}
                                                            onChange={countryChangeHandler}
                                                            disabled={groupLeaderId}
                                                            label="Country"
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".country"]}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        {/* <Dropdown menuClassName='myMenuClassName' id="state"
                                                        name="state"
                                                        options={stateData} placeholder="Select State"
                                                        value={stateId}
                                                        onChange={stateChangeHandler}
                                                        disabled={groupLeaderId}
                                                    /> */}
                                                        <Select
                                                            options={stateData}
                                                            name="state"
                                                            value={stateId}
                                                            onChange={stateChangeHandler}
                                                            placeholder="Select State"
                                                            isSearchable
                                                            isDisabled={groupLeaderId || stateUser || districtUser}
                                                            styles={selectStyles}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".state"]}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        {/* <Dropdown menuClassName='myMenuClassName' id="district"
                                                        name="district"
                                                        options={districtData} placeholder="Select District"
                                                        value={districtId}
                                                        onChange={districtChangeHandler}
                                                        disabled={groupLeaderId}
                                                    /> */}
                                                        <Select className="basic-single"
                                                            options={districtData}
                                                            name="district"
                                                            value={districtId}
                                                            onChange={districtChangeHandler}
                                                            isSearchable
                                                            isDisabled={groupLeaderId || districtUser}
                                                            placeholder="Select District"
                                                            styles={selectStyles}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".district"]}</div>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <MDInput label="Mobile No" fullWidth value={state.mobileno || ''}
                                                            name="mobileno"
                                                            onChange={changeHandler}
                                                            id="mobileno"
                                                            required
                                                            disabled={groupLeaderId}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".mobileno"]}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <MDInput label="Email" fullWidth value={state.emailid || ''}
                                                            name="emailid"
                                                            type="email"
                                                            onChange={changeHandler}
                                                            id="emailid"
                                                            required
                                                            disabled={groupLeaderId}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".emailId"]}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <MDInput label="Center" fullWidth value={state.leadercenter || ''}
                                                            name="leadercenter"
                                                            onChange={changeHandler}
                                                            id="leadercenter"
                                                            disabled={groupLeaderId}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".leadercenter"]}</div>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <Dropdown menuClassName='myMenuClassName' id="leadergender"
                                                            name="leadergender"
                                                            options={genderData}
                                                            value={leaderGender}
                                                            onChange={ev => setLeaderGender(ev)}
                                                            placeholder="Select Gender"
                                                            disabled={groupLeaderId}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".leadergender"]}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <MDInput label="PAN" fullWidth value={state.leaderpan || ''}
                                                            name="leaderpan"
                                                            onChange={changeHandler}
                                                            id="leaderpan"
                                                            disabled={groupLeaderId}
                                                            required
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".leaderpan"]}</div>
                                                    </TableCell>
                                                    <TableCell />
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                        <hr />
                                        <br />
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>
                                                        <Dropdown menuClassName='myMenuClassName' id="donationType"
                                                            name="donationType"
                                                            options={donationTypeData}
                                                            value={donationTypeId}
                                                            onChange={donationChangeHandler}
                                                            label="Donation Type"
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".donationType"]}</div>
                                                    </TableCell>
                                                    <TableCell colSpan={2}>
                                                        <div style={{ fontSize: '13px', color: 'green', fontWeight: 'bold' }}>Adult Contribution : {adultContri}
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Yuva Contribution : {yuvaContri}
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Balshakti Contribution : {balContri}
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell colSpan={3}>
                                                        <Table>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell>
                                                                        <div style={{ fontSize: '16px', fontWeight: 'bold' }}>Donor Details</div>
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell>
                                                                        <MDInput label="First Name" fullWidth value={state.firstname1 || ''}
                                                                            name="firstname1"
                                                                            onChange={changeHandler}
                                                                            id="firstname1"
                                                                            required />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".firstname1"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Middle Name" fullWidth value={state.middlename1 || ''}
                                                                            name="middlename1"
                                                                            onChange={changeHandler}
                                                                            id="middlename1"
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".middlename1"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Last Name" fullWidth value={state.lastname1 || ''}
                                                                            name="lastname1"
                                                                            onChange={changeHandler}
                                                                            id="lastname1"
                                                                            required />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".lastname1"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Center" fullWidth value={state.center1 || ''}
                                                                            name="center1"
                                                                            onChange={changeHandler}
                                                                            id="center1"
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".center1"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="PAN" fullWidth value={state.pan1 || ''}
                                                                            name="pan1"
                                                                            onChange={changeHandler}
                                                                            id="pan1"
                                                                            required
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".pan1"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div style={{ fontSize: '12px' }}>
                                                                            <Dropdown menuClassName='myMenuClassName' id="gender1"
                                                                                name="gender1"
                                                                                options={genderData}
                                                                                value={gender1}
                                                                                onChange={ev => setGender1(ev)}
                                                                                placeholder="Select Gender"
                                                                            />
                                                                        </div>
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".gender1"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div style={{ fontSize: '12px' }}>
                                                                            <Dropdown menuClassName='myMenuClassName' id="donorType1"
                                                                                name="donorType1"
                                                                                options={donorTypeData}
                                                                                value={donorType1}
                                                                                onChange={donorType1ChangeHandler}
                                                                                placeholder="Type"
                                                                            />
                                                                        </div>
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".donorType1"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div style={{ fontSize: '12px', width: '80px' }}>
                                                                            Senior Citizen
                                                                            <Checkbox menuClassName='myMenuClassName' id="seniorCitizen1"
                                                                                name="seniorCitizen1"
                                                                                checked={seniorCitizen1}
                                                                                onChange={seniorCitizen1ChangeHandler}
                                                                            />
                                                                        </div>
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".seniorCitizen1"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Contribution" fullWidth value={state.contribution1}
                                                                            name="contribution1"
                                                                            onChange={changeHandler}
                                                                            id="contribution1"
                                                                            required
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".contribution1"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Pass No" fullWidth value={state.coupono1}
                                                                            name="coupono1"
                                                                            onChange={changeHandler}
                                                                            id="coupono1"
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".coupono1"]}</div>
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell>
                                                                        <MDInput label="First Name" fullWidth value={state.firstname2 || ''}
                                                                            name="firstname2"
                                                                            onChange={changeHandler}
                                                                            id="firstname2"
                                                                            required />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".firstname2"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Middle Name" fullWidth value={state.middlename2 || ''}
                                                                            name="middlename2"
                                                                            onChange={changeHandler}
                                                                            id="middlename2"
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".middlename2"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Last Name" fullWidth value={state.lastname2 || ''}
                                                                            name="lastname2"
                                                                            onChange={changeHandler}
                                                                            id="lastname2"
                                                                            required />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".lastname2"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Center" fullWidth value={state.center2 || ''}
                                                                            name="center2"
                                                                            onChange={changeHandler}
                                                                            id="center2"
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".center2"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="PAN" fullWidth value={state.pan2 || ''}
                                                                            name="pan2"
                                                                            onChange={changeHandler}
                                                                            id="pan2"
                                                                            required
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".pan2"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div style={{ fontSize: '12px' }}>
                                                                            <Dropdown menuClassName='myMenuClassName' id="gender2"
                                                                                name="gender2"
                                                                                options={genderData}
                                                                                value={gender2}
                                                                                onChange={ev => setGender2(ev)}
                                                                                placeholder="Select Gender"
                                                                            />
                                                                        </div>
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".gender2"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div style={{ fontSize: '12px' }}>
                                                                            <Dropdown menuClassName='myMenuClassName' id="donorType2"
                                                                                name="donorType2"
                                                                                options={donorTypeData}
                                                                                value={donorType2}
                                                                                onChange={donorType2ChangeHandler}
                                                                                placeholder="Type"
                                                                            />
                                                                        </div>
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".donorType2"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div style={{ fontSize: '12px', width: '80px' }}>
                                                                            <Checkbox menuClassName='myMenuClassName' id="seniorCitizen2"
                                                                                name="seniorCitizen2"
                                                                                value={seniorCitizen2}
                                                                                onChange={seniorCitizen2ChangeHandler}
                                                                            />
                                                                        </div>
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".seniorCitizen2"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Contribution" fullWidth value={state.contribution2}
                                                                            name="contribution2"
                                                                            onChange={changeHandler}
                                                                            id="contribution2"
                                                                            required
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".contribution2"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Pass No" fullWidth value={state.coupono2}
                                                                            name="coupono2"
                                                                            onChange={changeHandler}
                                                                            id="coupono2"
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".coupono2"]}</div>
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell>
                                                                        <MDInput label="First Name" fullWidth value={state.firstname3 || ''}
                                                                            name="firstname3"
                                                                            onChange={changeHandler}
                                                                            id="firstname3"
                                                                            required />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".firstname3"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Middle Name" fullWidth value={state.middlename3 || ''}
                                                                            name="middlename3"
                                                                            onChange={changeHandler}
                                                                            id="middlename3"
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".middlename3"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Last Name" fullWidth value={state.lastname3 || ''}
                                                                            name="lastname3"
                                                                            onChange={changeHandler}
                                                                            id="lastname3"
                                                                            required />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".lastname3"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Center" fullWidth value={state.center3 || ''}
                                                                            name="center3"
                                                                            onChange={changeHandler}
                                                                            id="center3"
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".center3"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="PAN" fullWidth value={state.pan3 || ''}
                                                                            name="pan3"
                                                                            onChange={changeHandler}
                                                                            id="pan3"
                                                                            required
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".pan3"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div style={{ fontSize: '12px' }}>
                                                                            <Dropdown menuClassName='myMenuClassName' id="gender3"
                                                                                name="gender3"
                                                                                options={genderData}
                                                                                value={gender3}
                                                                                onChange={ev => setGender3(ev)}
                                                                                placeholder="Select Gender"
                                                                            />
                                                                        </div>
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".gender3"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div style={{ fontSize: '12px' }}>
                                                                            <Dropdown menuClassName='myMenuClassName' id="donorType3"
                                                                                name="donorType3"
                                                                                options={donorTypeData}
                                                                                value={donorType3}
                                                                                onChange={donorType3ChangeHandler}
                                                                                placeholder="Type"
                                                                            />
                                                                        </div>
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".donorType3"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div style={{ fontSize: '12px', width: '80px' }}>
                                                                            <Checkbox menuClassName='myMenuClassName' id="seniorCitizen3"
                                                                                name="seniorCitizen3"
                                                                                value={seniorCitizen3}
                                                                                onChange={seniorCitizen3ChangeHandler}
                                                                            />
                                                                        </div>
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".seniorCitizen3"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Contribution" fullWidth value={state.contribution3}
                                                                            name="contribution3"
                                                                            onChange={changeHandler}
                                                                            id="contribution3"
                                                                            required
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".contribution3"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Pass No" fullWidth value={state.coupono3}
                                                                            name="coupono3"
                                                                            onChange={changeHandler}
                                                                            id="coupono3"
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".coupono3"]}</div>
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell>
                                                                        <MDInput label="First Name" fullWidth value={state.firstname4 || ''}
                                                                            name="firstname4"
                                                                            onChange={changeHandler}
                                                                            id="firstname4"
                                                                            required />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".firstname4"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Middle Name" fullWidth value={state.middlename4 || ''}
                                                                            name="middlename4"
                                                                            onChange={changeHandler}
                                                                            id="middlename4"
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".middlename4"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Last Name" fullWidth value={state.lastname4 || ''}
                                                                            name="lastname4"
                                                                            onChange={changeHandler}
                                                                            id="lastname4"
                                                                            required />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".lastname4"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Center" fullWidth value={state.center4 || ''}
                                                                            name="center4"
                                                                            onChange={changeHandler}
                                                                            id="center4"
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".center4"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="PAN" fullWidth value={state.pan4 || ''}
                                                                            name="pan4"
                                                                            onChange={changeHandler}
                                                                            id="pan4"
                                                                            required
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".pan4"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div style={{ fontSize: '12px' }}>
                                                                            <Dropdown menuClassName='myMenuClassName' id="gender4"
                                                                                name="gender4"
                                                                                options={genderData}
                                                                                value={gender4}
                                                                                onChange={ev => setGender4(ev)}
                                                                                placeholder="Select Gender"
                                                                            />
                                                                        </div>
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".gender4"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div style={{ fontSize: '12px' }}>
                                                                            <Dropdown menuClassName='myMenuClassName' id="donorType4"
                                                                                name="donorType4"
                                                                                options={donorTypeData}
                                                                                value={donorType4}
                                                                                onChange={donorType4ChangeHandler}
                                                                                placeholder="Type"
                                                                            />
                                                                        </div>
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".donorType4"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div style={{ fontSize: '12px' }}>
                                                                            <Checkbox menuClassName='myMenuClassName' id="seniorCitizen4"
                                                                                name="seniorCitizen4"
                                                                                value={seniorCitizen4}
                                                                                onChange={seniorCitizen4ChangeHandler}
                                                                            />
                                                                        </div>
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".seniorCitizen4"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Contribution" fullWidth value={state.contribution4}
                                                                            name="contribution4"
                                                                            onChange={changeHandler}
                                                                            id="contribution4"
                                                                            required
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".contribution4"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Pass No" fullWidth value={state.coupono4}
                                                                            name="coupono4"
                                                                            onChange={changeHandler}
                                                                            id="coupono4"
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".coupono4"]}</div>
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell>
                                                                        <MDInput label="First Name" fullWidth value={state.firstname5 || ''}
                                                                            name="firstname5"
                                                                            onChange={changeHandler}
                                                                            id="firstname5"
                                                                            required />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".firstname5"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Middle Name" fullWidth value={state.middlename5 || ''}
                                                                            name="middlename5"
                                                                            onChange={changeHandler}
                                                                            id="middlename5"
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".middlename5"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Last Name" fullWidth value={state.lastname5 || ''}
                                                                            name="lastname5"
                                                                            onChange={changeHandler}
                                                                            id="lastname5"
                                                                            required />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".lastname5"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Center" fullWidth value={state.center5 || ''}
                                                                            name="center5"
                                                                            onChange={changeHandler}
                                                                            id="center5" />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".center5"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="PAN" fullWidth value={state.pan5 || ''}
                                                                            name="pan5"
                                                                            onChange={changeHandler}
                                                                            id="pan5"
                                                                            required
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".pan5"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div style={{ fontSize: '12px' }}>
                                                                            <Dropdown menuClassName='myMenuClassName' id="gender5"
                                                                                name="gender5"
                                                                                options={genderData}
                                                                                value={gender5}
                                                                                onChange={ev => setGender5(ev)}
                                                                                placeholder="Select Gender"
                                                                            />
                                                                        </div>
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".gender5"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div style={{ fontSize: '12px' }}>
                                                                            <Dropdown menuClassName='myMenuClassName' id="donorType5"
                                                                                name="donorType5"
                                                                                options={donorTypeData}
                                                                                value={donorType5}
                                                                                onChange={donorType5ChangeHandler}
                                                                                placeholder="Type"
                                                                            />
                                                                        </div>
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".donorType5"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div style={{ fontSize: '12px' }}>
                                                                            <Checkbox menuClassName='myMenuClassName' id="seniorCitizen5"
                                                                                name="seniorCitizen5"
                                                                                value={seniorCitizen5}
                                                                                onChange={seniorCitizen5ChangeHandler}
                                                                            />
                                                                        </div>
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".seniorCitizen5"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Contribution" fullWidth value={state.contribution5}
                                                                            name="contribution5"
                                                                            onChange={changeHandler}
                                                                            id="contribution5"
                                                                            required
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".contribution5"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Pass No" fullWidth value={state.coupono5}
                                                                            name="coupono5"
                                                                            onChange={changeHandler}
                                                                            id="coupono5"
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".coupono5"]}</div>
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell>
                                                                        <MDInput label="First Name" fullWidth value={state.firstname6 || ''}
                                                                            name="firstname6"
                                                                            onChange={changeHandler}
                                                                            id="firstname6"
                                                                            required />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".firstname6"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Middle Name" fullWidth value={state.middlename6 || ''}
                                                                            name="middlename6"
                                                                            onChange={changeHandler}
                                                                            id="middlename6"
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".middlename6"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Last Name" fullWidth value={state.lastname6 || ''}
                                                                            name="lastname6"
                                                                            onChange={changeHandler}
                                                                            id="lastname6"
                                                                            required />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".lastname6"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Center" fullWidth value={state.center6 || ''}
                                                                            name="center6"
                                                                            onChange={changeHandler}
                                                                            id="center6" />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".center6"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="PAN" fullWidth value={state.pan6 || ''}
                                                                            name="pan6"
                                                                            onChange={changeHandler}
                                                                            id="pan6"
                                                                            required
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".pan6"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div style={{ fontSize: '12px' }}>
                                                                            <Dropdown menuClassName='myMenuClassName' id="gender6"
                                                                                name="gender6"
                                                                                options={genderData}
                                                                                value={gender6}
                                                                                onChange={ev => setGender6(ev)}
                                                                                placeholder="Select Gender"
                                                                            />
                                                                        </div>
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".gender6"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div style={{ fontSize: '12px' }}>
                                                                            <Dropdown menuClassName='myMenuClassName' id="donorType6"
                                                                                name="donorType6"
                                                                                options={donorTypeData}
                                                                                value={donorType6}
                                                                                onChange={donorType6ChangeHandler}
                                                                                placeholder="Type"
                                                                            />
                                                                        </div>
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".donorType6"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div style={{ fontSize: '12px' }}>
                                                                            <Checkbox menuClassName='myMenuClassName' id="seniorCitizen6"
                                                                                name="seniorCitizen6"
                                                                                value={seniorCitizen6}
                                                                                onChange={seniorCitizen6ChangeHandler}
                                                                            />
                                                                        </div>
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".seniorCitizen6"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Contribution" fullWidth value={state.contribution6}
                                                                            name="contribution6"
                                                                            onChange={changeHandler}
                                                                            id="contribution6"
                                                                            required
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".contribution6"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Pass No" fullWidth value={state.coupono6}
                                                                            name="coupono6"
                                                                            onChange={changeHandler}
                                                                            id="coupono6"
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".coupono6"]}</div>
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell>
                                                                        <MDInput label="First Name" fullWidth value={state.firstname7 || ''}
                                                                            name="firstname7"
                                                                            onChange={changeHandler}
                                                                            id="firstname7"
                                                                            required />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".firstname7"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Middle Name" fullWidth value={state.middlename7 || ''}
                                                                            name="middlename7"
                                                                            onChange={changeHandler}
                                                                            id="middlename7"
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".middlename7"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Last Name" fullWidth value={state.lastname7 || ''}
                                                                            name="lastname7"
                                                                            onChange={changeHandler}
                                                                            id="lastname7"
                                                                            required />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".lastname7"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Center" fullWidth value={state.center7 || ''}
                                                                            name="center7"
                                                                            onChange={changeHandler}
                                                                            id="center7" />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".center7"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="PAN" fullWidth value={state.pan7 || ''}
                                                                            name="pan7"
                                                                            onChange={changeHandler}
                                                                            id="pan7"
                                                                            required
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".pan7"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div style={{ fontSize: '12px' }}>
                                                                            <Dropdown menuClassName='myMenuClassName' id="gender7"
                                                                                name="gender7"
                                                                                options={genderData}
                                                                                value={gender7}
                                                                                onChange={ev => setGender7(ev)}
                                                                                placeholder="Select Gender"
                                                                            />
                                                                        </div>
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".gender7"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div style={{ fontSize: '12px' }}>
                                                                            <Dropdown menuClassName='myMenuClassName' id="donorType7"
                                                                                name="donorType7"
                                                                                options={donorTypeData}
                                                                                value={donorType7}
                                                                                onChange={donorType7ChangeHandler}
                                                                                placeholder="Type"
                                                                            />
                                                                        </div>
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".donorType7"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div style={{ fontSize: '12px' }}>
                                                                            <Checkbox menuClassName='myMenuClassName' id="seniorCitizen7"
                                                                                name="seniorCitizen7"
                                                                                value={seniorCitizen7}
                                                                                onChange={seniorCitizen7ChangeHandler}
                                                                            />
                                                                        </div>
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".seniorCitizen7"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Contribution" fullWidth value={state.contribution7}
                                                                            name="contribution7"
                                                                            onChange={changeHandler}
                                                                            id="contribution7"
                                                                            required
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".contribution7"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Pass No" fullWidth value={state.coupono7}
                                                                            name="coupono7"
                                                                            onChange={changeHandler}
                                                                            id="coupono7"
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".coupono7"]}</div>
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell>
                                                                        <MDInput label="First Name" fullWidth value={state.firstname8 || ''}
                                                                            name="firstname8"
                                                                            onChange={changeHandler}
                                                                            id="firstname8"
                                                                            required />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".firstname8"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Middle Name" fullWidth value={state.middlename8 || ''}
                                                                            name="middlename8"
                                                                            onChange={changeHandler}
                                                                            id="middlename8"
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".middlename8"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Last Name" fullWidth value={state.lastname8 || ''}
                                                                            name="lastname8"
                                                                            onChange={changeHandler}
                                                                            id="lastname8"
                                                                            required />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".lastname8"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Center" fullWidth value={state.center8 || ''}
                                                                            name="center8"
                                                                            onChange={changeHandler}
                                                                            id="center8" />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".center8"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="PAN" fullWidth value={state.pan8 || ''}
                                                                            name="pan8"
                                                                            onChange={changeHandler}
                                                                            id="pan8"
                                                                            required
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".pan8"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div style={{ fontSize: '12px' }}>
                                                                            <Dropdown menuClassName='myMenuClassName' id="gender8"
                                                                                name="gender8"
                                                                                options={genderData}
                                                                                value={gender8}
                                                                                onChange={ev => setGender8(ev)}
                                                                                placeholder="Select Gender"
                                                                            />
                                                                        </div>
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".gender8"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div style={{ fontSize: '12px' }}>
                                                                            <Dropdown menuClassName='myMenuClassName' id="donorType8"
                                                                                name="donorType8"
                                                                                options={donorTypeData}
                                                                                value={donorType8}
                                                                                onChange={donorType8ChangeHandler}
                                                                                placeholder="Type"
                                                                            />
                                                                        </div>
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".donorType8"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div style={{ fontSize: '12px' }}>
                                                                            <Checkbox menuClassName='myMenuClassName' id="seniorCitizen8"
                                                                                name="seniorCitizen8"
                                                                                value={seniorCitizen8}
                                                                                onChange={seniorCitizen8ChangeHandler}
                                                                            />
                                                                        </div>
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".seniorCitizen8"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Contribution" fullWidth value={state.contribution8}
                                                                            name="contribution8"
                                                                            onChange={changeHandler}
                                                                            id="contribution8"
                                                                            required
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".contribution8"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Pass No" fullWidth value={state.coupono8}
                                                                            name="coupono8"
                                                                            onChange={changeHandler}
                                                                            id="coupono8"
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".coupono8"]}</div>
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell>
                                                                        <MDInput label="First Name" fullWidth value={state.firstname9 || ''}
                                                                            name="firstname9"
                                                                            onChange={changeHandler}
                                                                            id="firstname9"
                                                                            required />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".firstname9"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Middle Name" fullWidth value={state.middlename9 || ''}
                                                                            name="middlename9"
                                                                            onChange={changeHandler}
                                                                            id="middlename9"
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".middlename9"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Last Name" fullWidth value={state.lastname9 || ''}
                                                                            name="lastname9"
                                                                            onChange={changeHandler}
                                                                            id="lastname9"
                                                                            required />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".lastname9"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Center" fullWidth value={state.center9 || ''}
                                                                            name="center9"
                                                                            onChange={changeHandler}
                                                                            id="center9"
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".center9"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="PAN" fullWidth value={state.pan9 || ''}
                                                                            name="pan9"
                                                                            onChange={changeHandler}
                                                                            id="pan9"
                                                                            required
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".pan9"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div style={{ fontSize: '12px' }}>
                                                                            <Dropdown menuClassName='myMenuClassName' id="gender9"
                                                                                name="gender9"
                                                                                options={genderData}
                                                                                value={gender9}
                                                                                onChange={ev => setGender9(ev)}
                                                                                placeholder="Select Gender"
                                                                            />
                                                                        </div>
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".gender9"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div style={{ fontSize: '12px' }}>
                                                                            <Dropdown menuClassName='myMenuClassName' id="donorType9"
                                                                                name="donorType9"
                                                                                options={donorTypeData}
                                                                                value={donorType9}
                                                                                onChange={donorType9ChangeHandler}
                                                                                placeholder="Type"
                                                                            />
                                                                        </div>
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".donorType9"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div style={{ fontSize: '12px' }}>
                                                                            <Checkbox menuClassName='myMenuClassName' id="seniorCitizen9"
                                                                                name="seniorCitizen9"
                                                                                value={seniorCitizen9}
                                                                                onChange={seniorCitizen9ChangeHandler}
                                                                            />
                                                                        </div>
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".seniorCitizen9"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Contribution" fullWidth value={state.contribution9}
                                                                            name="contribution9"
                                                                            onChange={changeHandler}
                                                                            id="contribution9"
                                                                            required
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".contribution9"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Pass No" fullWidth value={state.coupono9}
                                                                            name="coupono9"
                                                                            onChange={changeHandler}
                                                                            id="coupono9"
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".coupono9"]}</div>
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell>
                                                                        <MDInput label="First Name" fullWidth value={state.firstname10 || ''}
                                                                            name="firstname10"
                                                                            onChange={changeHandler}
                                                                            id="firstname10"
                                                                            required />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".firstname10"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Middle Name" fullWidth value={state.middlename10 || ''}
                                                                            name="middlename10"
                                                                            onChange={changeHandler}
                                                                            id="middlename10"
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".middlename10"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Last Name" fullWidth value={state.lastname10 || ''}
                                                                            name="lastname10"
                                                                            onChange={changeHandler}
                                                                            id="lastname10"
                                                                            required />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".lastname10"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Center" fullWidth value={state.center10 || ''}
                                                                            name="center10"
                                                                            onChange={changeHandler}
                                                                            id="center10"
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".center10"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="PAN" fullWidth value={state.pan10 || ''}
                                                                            name="pan10"
                                                                            onChange={changeHandler}
                                                                            id="pan10"
                                                                            required
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".pan10"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div style={{ fontSize: '12px' }}>
                                                                            <Dropdown menuClassName='myMenuClassName' id="gender10"
                                                                                name="gender10"
                                                                                options={genderData}
                                                                                value={gender10}
                                                                                onChange={ev => setGender10(ev)}
                                                                                placeholder="Select Gender"
                                                                            />
                                                                        </div>
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".gender10"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div style={{ fontSize: '12px' }}>
                                                                            <Dropdown menuClassName='myMenuClassName' id="donorType10"
                                                                                name="donorType10"
                                                                                options={donorTypeData}
                                                                                value={donorType10}
                                                                                onChange={donorType10ChangeHandler}
                                                                                placeholder="Type"
                                                                            />
                                                                        </div>
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".donorType10"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div style={{ fontSize: '12px' }}>
                                                                            <Checkbox menuClassName='myMenuClassName' id="seniorCitizen10"
                                                                                name="seniorCitizen10"
                                                                                value={seniorCitizen10}
                                                                                onChange={seniorCitizen10ChangeHandler}
                                                                            />
                                                                        </div>
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".seniorCitizen10"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Contribution" fullWidth value={state.contribution10}
                                                                            name="contribution10"
                                                                            onChange={changeHandler}
                                                                            id="contribution10"
                                                                            required
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".contribution10"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Pass No" fullWidth value={state.coupono10}
                                                                            name="coupono10"
                                                                            onChange={changeHandler}
                                                                            id="coupono10"
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".coupono10"]}</div>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>

                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <div style={{ fontSize: '16px', fontWeight: 'bold' }}>Payment Details</div>
                                                    </TableCell>
                                                    <TableCell />
                                                    <TableCell />
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <MDInput label="Total Contribution" fullWidth value={contribution}
                                                            name="contribution"
                                                            onChange={changeHandler}
                                                            id="contribution"
                                                            required />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".contribution"]}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Dropdown menuClassName='myMenuClassName' id="currency"
                                                            name="currency"
                                                            options={currencyData}
                                                            value={currency}
                                                            onChange={ev => setCurrency(ev)}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".currency"]}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Dropdown menuClassName='myMenuClassName' id="paymentMode"
                                                            name="paymentMode"
                                                            options={paymentModeData}
                                                            value={paymentMode}
                                                            onChange={ev => setPaymentMode(ev)}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".paymentMode"]}</div>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        Payment Date
                                                        <DatePicker
                                                            id="paymentDate"
                                                            selected={paymentDate}
                                                            onChange={date => setPaymentDate(date)}
                                                            dateFormat="MM/dd/yyyy"
                                                            tabIndex={0}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".paymentDate"]}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Dropdown menuClassName='myMenuClassName' id="bank"
                                                            name="bank"
                                                            options={bankData}
                                                            value={bankId}
                                                            onChange={bankChangeHandler}
                                                            label="Bank"
                                                            placeholder="Select Bank"
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".bank"]}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <MDInput label="UTR Code" fullWidth value={state.utrCode || ''}
                                                            name="utrCode"
                                                            onChange={changeHandler}
                                                            id="utrCode"
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".utrCode"]}</div>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <MDBox mt={4} mb={1} textAlign="center">
                                        {loading ? <Oval
                                            heigth="20"
                                            width="20"
                                            color='grey'
                                            ariaLabel='loading'
                                        /> :
                                            <MDButton color="info" onClick={submitHandler}>
                                                SAVE
                                            </MDButton>
                                        }
                                        {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                    </MDBox>

                                </Card>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
            }
            <Footer />
        </DashboardLayout>
    );
}

export default AddGroupBooking;