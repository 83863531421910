
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
// import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from 'moment';
import { Checkbox } from "@mui/material";
import Select from 'react-select';
import * as service from '../../../services/service';

function AddIndividualBooking() {
    const [state, setState] = useState(
        {
            firstname: '', middlename: '', lastname: '', center: '', emailid: '', mobileno: '', registrationType: '',
            pan: '', utrCode: '', contribution: '', couponno: ''
        });
    const [countryId, setCountryId] = useState('');
    const [countryData, setCountryData] = useState([]);
    const [stateId, setStateId] = useState('');
    const [stateData, setStateData] = useState([]);
    const [districtId, setDistrictId] = useState('');
    const [districtData, setDistrictData] = useState([]);
    const [donationTypeId, setDonationTypeId] = useState('');
    const [donationTypeData, setDonationTypeData] = useState([]);
    const [bankId, setBankId] = useState('');
    const [bankData, setBankData] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [gender, setGender] = useState('');
    const [donorType, setDonorType] = useState('');
    const [seniorCitizen, setSeniorCitizen] = useState(false);
    const [currency, setCurrency] = useState('');
    const [paymentMode, setPaymentMode] = useState('');
    const [paymentDate, setPaymentDate] = useState(new Date());
    const genderData = [{ label: "Male", value: "Male" }, { label: "Female", value: "Female" }];
    const donorTypeData = [{ label: "Adult", value: "Adult" }, { label: "Yuva", value: "Yuvashakti" }, { label: "Bal", value: "Balshakti" }];
    const currencyData = [{ label: "INR", value: "INR" }];
    const paymentModeData = [{ label: "Cash", value: "Cash" }, { label: "Bank Transfer", value: "Bank Transfer" },
    { label: "QR Code", value: "QR Code" }, { label: "Online", value: "Online" }];
    const [adultContri, setAdultContri] = useState('');
    const [yuvaContri, setYuvaContri] = useState('');
    const [balContri, setBalContri] = useState('');
    const [onlinePay, setOnlinePay] = useState(false);
    const [stateUser, setStateUser] = useState(false);
    const [districtUser, setDistrictUser] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const useremail = JSON.parse(sessionStorage.getItem('userData')).emailId;
    // const navigate = useNavigate();
    // const params = useParams();
    const getDonationTypes = () => {
        fetch(
            service.DONATION_TYPE_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.donationTypeId, label: item.name }));
                setDonationTypeData(data2);
                // assuming single database per puja
                setAdultContri(result[0].adultContri);
                setYuvaContri(result[0].yuvaContri);
                setBalContri(result[0].balContri);

            });
    };

    const getCountries = () => {
        fetch(
            service.COUNTRY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.countryId, label: item.country }));
                setCountryData(data2);
            });
    };
    const getStates = (id) => {
        fetch(
            service.GET_STATE_BY_COUNTRY_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CountryId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.stateId, label: item.state }));
                setStateData(data2);

                if (JSON.parse(sessionStorage.getItem('userData')).userRole !== 'Global') {
                    const st = data2.find(x => x.value === JSON.parse(sessionStorage.getItem('userData')).stateId);
                    setStateId(st);
                }
            });
    };

    const getDistricts = (id) => {
        fetch(
            service.GET_DISTRICT_BY_STATE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    StateId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.districtId, label: item.district }));
                setDistrictData(data2);

                if (JSON.parse(sessionStorage.getItem('userData')).userRole !== 'Global') {
                    const dt = data2.find(x => x.value === JSON.parse(sessionStorage.getItem('userData')).districtId);
                    setDistrictId(dt);
                }
            });
    };
    const getBanks = () => {
        fetch(
            service.BANK_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.bankId, label: item.bank }));
                setBankData(data2);
            });
    };

    useEffect(() => {
        getBanks();
        getCountries();
        getDonationTypes();
        const def = { value: 1, label: 'India' };
        setCountryId(def);
        getStates(def.value);
        if (JSON.parse(sessionStorage.getItem('userData')).userRole !== 'Global') {
            getDistricts(JSON.parse(sessionStorage.getItem('userData')).stateId);
        }

        if (JSON.parse(sessionStorage.getItem('userData')).userRole === 'State') {
            setStateUser(true);
        }
        else if (JSON.parse(sessionStorage.getItem('userData')).userRole === 'District') {
            setDistrictUser(true);
        }

        const cur = { label: 'INR', value: 'INR' };
        setCurrency(cur);

        const mode = { label: 'Cash', value: 'Cash' };
        setPaymentMode(mode);

        const gen = { label: 'Male', value: 'Male' };
        setGender(gen);

        const type = { value: 1, label: 'Diwali Puja 2024' };
        setDonationTypeId(type);
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!state.firstname) {
            isValid = false;
            errs[".firstname"] = "Enter first name";
        }
        if (!state.lastname) {
            isValid = false;
            errs[".lastname"] = "Enter last name";
        }
        if (!donorType) {
            isValid = false;
            errs[".donorType"] = "Select type";
        }
        if (!stateId) {
            isValid = false;
            errs[".state"] = "Please select state";
        }
        if (!districtId) {
            isValid = false;
            errs[".district"] = "Please select district";
        }
        if (!state.emailid) {
            isValid = false;
            errs[".emailId"] = "Please enter emailId";
        }
        if (!state.mobileno) {
            isValid = false;
            errs[".mobileno"] = "Please enter mobile no.";
        }
        if (!state.pan) {
            isValid = false;
            errs[".pan"] = "Please enter PAN";
        }
        if (!state.contribution) {
            isValid = false;
            errs[".contribution"] = "Please enter contribution";
        }
        if (paymentMode.value === "Bank Transfer" && !bankId) {
            isValid = false;
            errs[".bank"] = "Please select bank";
        }
        if (paymentMode.value === "Bank Transfer" && !state.utrCode) {
            isValid = false;
            errs[".utrCode"] = "Please enter UTRCode";
        }
        // if (state.emailid !== "undefined") {
        //     const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        //     if (!pattern.test(state.emailid)) {
        //       isValid = false;
        //       errs[".emailid"] = "Please enter valid email address";
        //     }
        //   }
        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const countryChangeHandler = (ev) => {
        setCountryId(ev);
        setStateId(null);
        setDistrictId(null);
        getStates(ev.value);
    }
    const stateChangeHandler = (ev) => {
        setStateId(ev);
        setDistrictId(null);
        getDistricts(ev.value);
    }
    const districtChangeHandler = (ev) => {
        setDistrictId(ev);
    }
    const donationChangeHandler = (ev) => {
        setDonationTypeId(ev);
    }
    const bankChangeHandler = (ev) => {
        setBankId(ev);
    }
    const paymentModeChangeHandler = (ev) => {
        setPaymentMode(ev);
        if (ev.value === "Online") {
            setOnlinePay(false);
        }
        else {
            setOnlinePay(false);
        }

    }
    const donorTypeChangeHandler = (ev) => {
        let contri = 0;
        if (ev.value === "Adult") {
            contri = adultContri;
        }
        else if (ev.value === "Yuvashakti") {
            contri = yuvaContri;
        }
        else if (ev.value === "Balshakti") {
            contri = balContri;
        }
        state.contribution = contri;
        setDonorType(ev);
    }

    const seniorCitizenChangeHandler = (ev) => {
        setSeniorCitizen(ev.target.checked);
    }

    function AddDonor(groupLeadId, firstName, middleName, lastName, cntryId, statId, distrId, cntr, gndr, pan, dnrType, senior, paymentId, couponno) {
        fetch(
            service.ADD_UPDATE_DONOR,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    DonorId: 0,
                    GroupLeaderId: groupLeadId,
                    FirstName: firstName,
                    MiddleName: middleName,
                    LastName: lastName,
                    CountryId: cntryId,
                    StateId: statId,
                    DistrictId: distrId,
                    Center: cntr,
                    Gender: gndr,
                    PAN: pan,
                    DonorType: dnrType,
                    IsSeniorCitizen: senior,
                    IsAccomodationRequired: true,
                    PaymentId: paymentId,
                    Contribution: state.contribution,
                    CouponNo: couponno,
                    AddedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                    UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.message.messageType === 'Failed') {
                    // eslint-disable-next-line
                    alert(result.message.message);
                }
                else if (result.message.messageType === 'Success') {
                    // eslint-disable-next-line
                    alert("Data Added Successfully.");
                    window.location.reload();
                }
            });
    }

    function AddPayment(grpLeadId, razorOrderId, razorPaymentId, razorSignature) {
        const errs = {};
        fetch(
            service.ADD_DONOR_PAYMENT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    GroupLeaderId: grpLeadId,
                    DonationTypeId: donationTypeId.value,
                    Contribution: state.contribution,
                    Currency: currency.value,
                    PaymentMode: paymentMode.value,
                    PaymentDate: moment(paymentDate).format("MM/DD/YYYY"),
                    UTRCode: state.utrCode,
                    BankId: bankId.value,
                    RazorOrderId: razorOrderId,
                    RazorPaymentId: razorPaymentId,
                    RazorSignature: razorSignature,
                    AddedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                    UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                })
            })
            .then(res => res.json())
            .then(resultP => {
                if (resultP.message.messageType === 'Success') {
                    AddDonor(grpLeadId, state.firstname, state.middlename, state.lastname, countryId.value, stateId.value, districtId.value,
                        state.center, gender.value, state.pan, donorType.value, seniorCitizen, resultP.paymentId, state.couponno);
                }
                else if (resultP.message.messageType === 'Failed') {
                    // eslint-disable-next-line
                    alert(resultP.message.message);
                }
                else {
                    errs[".invalid"] = resultP.message.message;
                    setErrors(errs);
                }
            });
    }

    function AddGroupLeader(razorOrderId, razorPaymentId, razorSignature) {
        fetch(
            service.ADD_UPDATE_GROUP_LEADER,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    GroupLeaderId: 0,
                    FirstName: state.firstname,
                    MiddleName: state.middlename,
                    LastName: state.lastname,
                    CountryId: countryId.value,
                    StateId: stateId.value,
                    DistrictId: districtId.value,
                    Center: state.center,
                    MobileNo: state.mobileno,
                    EmailId: state.emailid,
                    Gender: gender.value,
                    PAN: state.pan,
                    RegistrationType: 'IndividualByAdmin', // IndividualByAdmin, GroupLeaderBySelf, IndividualBySelf 
                    AddedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                    UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.message.messageType === 'Success') {
                    AddPayment(result.groupLeaderId, razorOrderId, razorPaymentId, razorSignature);
                }
                else if (result.message.messageType === 'Failed') {
                    // eslint-disable-next-line
                    alert(result.message.message);
                }
            });
    }

    const submitHandler = e => {
        e.preventDefault();
        setLoading(true);
        if (validate()) {
            AddGroupLeader('', '', '');
        }
        setLoading(false);
    }

    const handleConfirmResponse = (res) => {
        // console.log(res.razorpay_order_id);
        // console.log(res.razorpay_payment_id);
        // console.log(res.razorpay_signature);
        AddGroupLeader(res.razorpay_order_id, res.razorpay_payment_id, res.razorpay_signature);
    };

    const handleConfirm = (orderId) => {
        const options = {
            key: process.env.REACT_APP_RAZOR_KEY_ID,
            amount: state.contribution * 100,
            name: 'Puja Donation',
            description: 'Puja Donation',
            image: '',
            order_id: orderId,
            handler: handleConfirmResponse,
            prefill: {
                name: state.firstname + state.lastname,
                email: state.emailid,
                contact: state.mobileno
            },
            theme: {
                color: '#F37254'
            }
        };
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
    };

    const handleRazorpayPayment = () => {
        setLoading(true);
        if (validate()) {
            let orderId = 0;
            fetch(
                service.CREATE_PAYMENT_ORDER,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        Currency: currency.value,
                        Contribution: state.contribution * 100
                    })
                })
                .then((res) => res.json())
                .then((data) => {
                    orderId = data.id
                    handleConfirm(orderId)
                })
                .catch(err => {
                    // eslint-disable-next-line
                    alert("Error...", err);
                });
        }
        setLoading(false);
    };

    // const handleUnionBankPG = () => {
    //     setLoading(true);
    //     if (validate()) {
    //         // let orderId = 0;
    //         const login = '9132';
    //         const pass = 'Test@123';
    //         const prodid = 'NSE';
    //         const reqhashKey = 'KEY123657234';
    //         const resenckey = 'KEYRESP123657234';            
    //         const reqencryKey = 'A4476C2062FFA58980DC8F79EB6A799E';
    //         const reqsalt = 'A4476C2062FFA58980DC8F79EB6A799E';
    //         const respoencrykey = '75AEF0FA1B94B3C10D4F5B268F757F11';
    //         const resposalt = '75AEF0FA1B94B3C10D4F5B268F757F11';
    //         const cur = state.currency;
    //         const name = state.firstname + state.lastname;
    //         const ph = state.mobileno;
    //         const addr = state.center;            
    //         const clientcode = '007';
    //         const eml = state.emailid;
    //         const seturl = 'https://paynetzuat.atomtech.in/paynetz/epi/fts';
    //         const amt = state.contribution * 100;

    //         fetch(
    //             `https://paynetzuat.atomtech.in/paynetz/epi/fts?login=${login}&password=${pass}&prodid=${prodid}&currency=${cur}&amount=${amt}&usersname=${name}&emailId=${eml}&phone=${ph}&address=${addr}&clientcode=${clientcode}&reqhashKey=${reqhashKey}&resenckey=${resenckey}&seturl=${seturl}&requestEncypritonKey=${reqencryKey}&requestSalt=${reqsalt}&responseEncypritonKey=${respoencrykey}&responseSalt=${resposalt}`,
    //             {
    //                 mode: "no-cors",
    //                 method: 'POST',
    //                 // headers: {
    //                 //     'Accept': 'application/json',
    //                 //     'Content-Type': 'application/json'
    //                 // },                   
    //             })
    //             .then((res) => res.json())
    //             .then((data) => {
    //                 // orderId = data.id
    //                 // handleConfirm(orderId)
    //                 console.log(data);
    //             })
    //             .catch(err => {
    //                 // eslint-disable-next-line
    //                 console.log(err);
    //                 alert("Error...", err);
    //             });
    //     }
    //     setLoading(false);
    // }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            {useremail === 't@t.com' || useremail === 'a@a.com' ?
                <MDBox pt={6} pb={3}>
                    <div style={{ fontSize: '14px', fontWeight: 'bold', height: '500px', color: 'red' }} align='center'>
                        Access Denied
                    </div>
                </MDBox>
                :
                <MDBox pt={6} pb={3}>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                >
                                    <MDTypography variant="h6" color="white">
                                        Save Details
                                    </MDTypography>
                                </MDBox>
                                <Card style={{ alignItems: 'center' }}>
                                    <br />
                                    <TableContainer component={Paper} style={{ width: '98%', alignSelf: 'center', height: '900px' }}>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>
                                                        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>New Donor</div>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{ width: '35%' }}>
                                                        <MDInput label="First Name" fullWidth value={state.firstname || ''}
                                                            name="firstname"
                                                            onChange={changeHandler}
                                                            id="firstname"
                                                            required />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".firstname"]}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <MDInput label="Middle Name" fullWidth value={state.middlename || ''}
                                                            name="middlename"
                                                            onChange={changeHandler}
                                                            id="middlename"
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".middlename"]}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <MDInput label="Last Name" fullWidth value={state.lastname || ''}
                                                            name="lastname"
                                                            onChange={changeHandler}
                                                            id="lastname"
                                                            required />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".lastname"]}</div>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <Dropdown menuClassName='myMenuClassName' id="country"
                                                            name="country"
                                                            options={countryData}
                                                            value={countryId}
                                                            onChange={countryChangeHandler}
                                                            label="Country"
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".country"]}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        {/* <Dropdown menuClassName='myMenuClassName' id="state"
                                                        name="state"
                                                        options={stateData} placeholder="Select State"
                                                        value={stateId}
                                                        onChange={stateChangeHandler}
                                                    /> */}
                                                        <Select
                                                            options={stateData}
                                                            name="state"
                                                            value={stateId}
                                                            onChange={stateChangeHandler}
                                                            placeholder="Select State"
                                                            isSearchable
                                                            isDisabled={stateUser || districtUser}
                                                            styles={selectStyles}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".state"]}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        {/* <Dropdown menuClassName='myMenuClassName' id="district"
                                                        name="district"
                                                        options={districtData} placeholder="Select District"
                                                        value={districtId}
                                                        onChange={districtChangeHandler}
                                                    /> */}
                                                        <Select className="basic-single"
                                                            options={districtData}
                                                            name="district"
                                                            value={districtId}
                                                            onChange={districtChangeHandler}
                                                            isSearchable
                                                            placeholder="Select District"
                                                            isDisabled={districtUser}
                                                            styles={selectStyles}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".district"]}</div>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <MDInput label="Mobile No" fullWidth value={state.mobileno || ''}
                                                            name="mobileno"
                                                            onChange={changeHandler}
                                                            id="mobileno"
                                                            required
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".mobileno"]}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <MDInput label="Email" fullWidth value={state.emailid || ''}
                                                            name="emailid"
                                                            type="email"
                                                            onChange={changeHandler}
                                                            id="emailid"
                                                            required
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".emailId"]}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <MDInput label="Center" fullWidth value={state.center || ''}
                                                            name="center"
                                                            onChange={changeHandler}
                                                            id="center"
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".center"]}</div>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <Dropdown menuClassName='myMenuClassName' id="gender"
                                                            name="gender"
                                                            options={genderData}
                                                            value={gender}
                                                            onChange={ev => setGender(ev)}
                                                            placeholder="Select Gender"
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".gender"]}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <MDInput label="PAN" fullWidth value={state.pan || ''}
                                                            name="pan"
                                                            onChange={changeHandler}
                                                            id="pan"
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".pan"]}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <MDInput label="Pass No" fullWidth value={state.couponno || ''}
                                                            name="couponno"
                                                            onChange={changeHandler}
                                                            id="couponno"
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".couponno"]}</div>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <Dropdown menuClassName='myMenuClassName' id="donorType"
                                                            name="donorType"
                                                            options={donorTypeData}
                                                            value={donorType}
                                                            onChange={donorTypeChangeHandler}
                                                            placeholder="Type"
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".donorType"]}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        Senior Citizen
                                                        <Checkbox menuClassName='myMenuClassName' id="seniorCitizen"
                                                            name="seniorCitizen"
                                                            checked={seniorCitizen}
                                                            onChange={seniorCitizenChangeHandler}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".seniorCitizen"]}</div>
                                                    </TableCell>

                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                        <hr />
                                        <br />
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>
                                                        <div style={{ fontSize: '16px', fontWeight: 'bold' }}>Payment Details</div>
                                                    </TableCell>
                                                    <TableCell />
                                                    <TableCell />
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <Dropdown menuClassName='myMenuClassName' id="donationType"
                                                            name="donationType"
                                                            options={donationTypeData}
                                                            value={donationTypeId}
                                                            onChange={donationChangeHandler}
                                                            label="Donation Type"
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".donationType"]}</div>
                                                    </TableCell>
                                                    <TableCell colSpan={2}>
                                                        <div style={{ fontSize: '13px', color: 'green', fontWeight: 'bold' }}>Adult Contribution : {adultContri}
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Yuva Contribution : {yuvaContri}
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Balshakti Contribution : {balContri}
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <MDInput label="Contribution" fullWidth value={state.contribution}
                                                            name="contribution"
                                                            onChange={changeHandler}
                                                            id="contribution"
                                                            required
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".contribution"]}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Dropdown menuClassName='myMenuClassName' id="currency"
                                                            name="currency"
                                                            options={currencyData}
                                                            value={currency}
                                                            onChange={ev => setCurrency(ev)}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".currency"]}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Dropdown menuClassName='myMenuClassName' id="paymentMode"
                                                            name="paymentMode"
                                                            options={paymentModeData}
                                                            value={paymentMode}
                                                            onChange={paymentModeChangeHandler}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".paymentMode"]}</div>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        Payment Date
                                                        <DatePicker
                                                            id="paymentDate"
                                                            selected={paymentDate}
                                                            onChange={date => setPaymentDate(date)}
                                                            dateFormat="MM/dd/yyyy"
                                                            tabIndex={0}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".paymentDate"]}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Dropdown menuClassName='myMenuClassName' id="bank"
                                                            name="bank"
                                                            options={bankData}
                                                            value={bankId}
                                                            onChange={bankChangeHandler}
                                                            label="Bank"
                                                            placeholder="Select Bank"
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".bank"]}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <MDInput label="UTR Code" fullWidth value={state.utrCode || ''}
                                                            name="utrCode"
                                                            onChange={changeHandler}
                                                            id="utrCode"
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".utrCode"]}</div>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Table style={{ width: '20%' }}>
                                        <TableRow>
                                            <TableCell>
                                                <MDBox mt={4} mb={1} textAlign="center">
                                                    {loading ? <Oval
                                                        heigth="20"
                                                        width="20"
                                                        color='grey'
                                                        ariaLabel='loading'
                                                    /> :
                                                        <MDButton color="info" onClick={submitHandler} disabled={onlinePay}>
                                                            SAVE
                                                        </MDButton>
                                                    }
                                                    {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                                </MDBox>
                                            </TableCell>
                                            <TableCell>
                                                <MDBox mt={4} mb={1} textAlign="center">
                                                    {loading ? <Oval
                                                        heigth="20"
                                                        width="20"
                                                        color='grey'
                                                        ariaLabel='loading'
                                                    /> :
                                                        <MDButton color="info" onClick={handleRazorpayPayment} disabled={!onlinePay}>
                                                            Pay Now
                                                        </MDButton>
                                                    }
                                                </MDBox>
                                            </TableCell>
                                        </TableRow>
                                    </Table>
                                </Card>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
            }
            <Footer />
        </DashboardLayout>
    );
}

export default AddIndividualBooking;