import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
// import MDAlert from "components/MDAlert";
import MDButton from 'components/MDButton';
import MDInput from "components/MDInput";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Oval } from 'react-loader-spinner';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import * as service from '../../services/service';

function Donor() {
    const [search, setSearch] = useState('');
    const [data, setData] = useState([]);
    const [groupLeaderId, setGroupLeaderId] = useState('');
    const [GroupLeaderData, setGroupLeaderData] = useState([]);
    const [error, setError] = useState(null);
    const [dataError, setDataError] = useState(null);
    const [loading, setLoading] = useState(false);
    const useremail = JSON.parse(sessionStorage.getItem('userData')).emailId;
    const navigate = useNavigate();

    const getGroupLeaders = (stId, dtId) => {
        fetch(
            service.GROUP_LEADERS_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    StateId: stId,
                    DistrictId: dtId
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.groupLeaderId, label: item.firstName.concat(" ", item.middleName).concat(" ", item.lastName) }));
                setGroupLeaderData(data2);
            });
    };

    const validate = () => {
        let isValid = true;
        if (!search && !groupLeaderId) {
            isValid = false;
            setError("Please enter search criteria or select group leader to get the donors list");
        }
        else {
            setError('');
        }
        return isValid;
    }
    const getDetails = () => {
        setLoading(true);
        if (validate()) {
            fetch(
                service.DONORS_BY_SEARCH_URL,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        Search: search,
                        GroupLeaderId: groupLeaderId.value,
                        StateId: JSON.parse(sessionStorage.getItem('userData')).stateId,
                        DistrictId: JSON.parse(sessionStorage.getItem('userData')).districtId
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result[0].message.messageType === "Failed") {
                        setDataError(result[0].message.message);
                        setData([]);
                    }
                    else {
                        setData(result);
                        setDataError('');
                    }
                })
                .catch(err => {
                    if (err) {
                        setDataError(err);
                    };
                });
        }
        setLoading(false);
    };

    const handleEdit = (id) => {
        navigate(`/editdonor/${id}`);
    }

    useEffect(() => {
        setLoading(true);
        getGroupLeaders(JSON.parse(sessionStorage.getItem('userData')).stateId, JSON.parse(sessionStorage.getItem('userData')).districtId);
        setLoading(false);
    }, []);

    const groupLeaderChangeHandler = (ev) => {
        setGroupLeaderId(ev);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {useremail === 't@t.com' || useremail === 'a@a.com' ?
                <MDBox pt={6} pb={3}>
                    <div style={{ fontSize: '14px', fontWeight: 'bold', height: '500px', color: 'red' }} align='center'>
                        Access Denied
                    </div>
                </MDBox>
                :
                <MDBox pt={6} pb={3}>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                >
                                    <MDTypography variant="h6" color="white">
                                        Donor Details
                                    </MDTypography>
                                </MDBox>
                                <br />
                                <MDBox mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}>
                                    <Stack direction="row" spacing={5}>
                                        <MDInput label="search" value={search}
                                            name="search"
                                            onChange={ev => setSearch(ev.target.value)}
                                            id="search"
                                            required
                                        />
                                        <div style={{ width: '20%', fontSize: '14px' }}>
                                            <Dropdown menuClassName='myMenuClassName' id="groupLeader"
                                                name="groupLeader"
                                                options={GroupLeaderData}
                                                value={groupLeaderId}
                                                onChange={groupLeaderChangeHandler}
                                                label="Group Leaders"
                                                placeholder="Select Group Leader"
                                            />
                                        </div>
                                        <MDButton color="info" onClick={getDetails} endIcon={<SearchIcon />}>
                                            Search
                                        </MDButton>
                                    </Stack>
                                    <br />
                                    <div style={{ fontSize: '12px', color: 'green' }}>Search by FirstName, LastName, District, PAN or Receipt No</div>
                                    <div style={{ fontSize: '12px', color: 'red' }}>{error}</div>
                                </MDBox>
                                <br />
                                <Card style={{ alignItems: 'center' }}>
                                    <div style={{ fontSize: '12px', color: 'red' }}>{dataError}</div>
                                    {loading ? <Oval
                                        heigth="100"
                                        width="100"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <TableContainer component={Paper} style={{ width: '98%', alignSelf: 'center' }}>
                                            <Table aria-label="simple table">
                                                <TableRow style={{ backgroundColor: 'lightgrey' }}>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Name</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>District</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Gender</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Type</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Senior Citizen</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>PAN</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Coupon No</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Group Leader</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Action</TableCell>
                                                </TableRow>

                                                <TableBody>
                                                    {data.map((row) => (
                                                        <TableRow
                                                            key={row.donorId}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell style={{ fontSize: '14px' }}>{row.fullName}</TableCell>
                                                            <TableCell style={{ fontSize: '14px' }}>{row.district}</TableCell>
                                                            <TableCell style={{ fontSize: '14px' }}>{row.gender}</TableCell>
                                                            <TableCell style={{ fontSize: '14px' }}>{row.donorType}</TableCell>
                                                            <TableCell style={{ fontSize: '14px' }}>{row.isSeniorCitizen ? 'Yes' : 'No'}</TableCell>
                                                            <TableCell style={{ fontSize: '14px' }}>{row.pan}</TableCell>
                                                            <TableCell style={{ fontSize: '14px' }}>{row.couponNo}</TableCell>
                                                            <TableCell style={{ fontSize: '14px' }}>{row.groupLeaderName}</TableCell>
                                                            <TableCell style={{ fontSize: '14px' }}><MDButton onClick={() => handleEdit(row.donorId)}>Edit</MDButton></TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    }
                                </Card>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
            }
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default Donor;