
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
// import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { Circles } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as moment from 'moment';
import { Radio } from '@mui/material';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
// import { jsPDF } from 'jspdf';
// import html2canvas from "html2canvas";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { styled } from '@mui/material/styles';

import * as service from '../../services/service';

function Reports() {
    const [reportDetailsData, setReportDetailsData] = useState([]);
    const [reportSummaryData, setReportSummaryData] = useState([]);
    const [countryId, setCountryId] = useState('');
    const [countryData, setCountryData] = useState([]);
    const [stateId, setStateId] = useState('');
    const [stateData, setStateData] = useState([]);
    const [districtId, setDistrictId] = useState('');
    const [districtData, setDistrictData] = useState([]);
    const [donationTypeId, setDonationTypeId] = useState('');
    const [donationTypeData, setDonationTypeData] = useState([]);
    const [showDetailsReport, setShowDetailsReport] = useState(false);
    const [showSummaryReport, setShowSummaryReport] = useState(false);
    const [reportType, setReportType] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [stateUser, setStateUser] = useState(false);
    const [districtUser, setDistrictUser] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    const getDonationTypes = () => {
        fetch(
            service.DONATION_TYPE_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.donationTypeId, label: item.name }));
                setDonationTypeData(data2);
            });
    };

    const getCountries = () => {
        fetch(
            service.COUNTRY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.countryId, label: item.country }));
                setCountryData(data2);
            });
    };
    const getStates = (id) => {
        fetch(
            service.GET_STATE_BY_COUNTRY_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CountryId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.stateId, label: item.state }));
                const all = ({ value: "0", label: "All" });
                data2.unshift(all);
                setStateData(data2);
                setStateId(all);

                if (JSON.parse(sessionStorage.getItem('userData')).userRole !== 'Global') {
                    const st = data2.find(x => x.value === JSON.parse(sessionStorage.getItem('userData')).stateId);
                    setStateId(st);
                }
            });
    };

    const getDistricts = (id) => {
        fetch(
            service.GET_DISTRICT_BY_STATE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    StateId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.districtId, label: item.district }));

                const all = ({ value: "0", label: "All" });
                data2.unshift(all);
                setDistrictData(data2);
                setDistrictId(all);

                if (JSON.parse(sessionStorage.getItem('userData')).userRole !== 'Global') {
                    const dt = data2.find(x => x.value === JSON.parse(sessionStorage.getItem('userData')).districtId);
                    setDistrictId(dt);
                }
            });
    };

    useEffect(() => {
        getCountries();
        getDonationTypes();

        const def = { value: 1, label: 'India' };
        setCountryId(def);
        getStates(def.value);

        if (JSON.parse(sessionStorage.getItem('userData')).userRole !== 'Global') {
            getDistricts(JSON.parse(sessionStorage.getItem('userData')).stateId);
        }

        if (JSON.parse(sessionStorage.getItem('userData')).userRole === 'State') {
            setStateUser(true);
        }
        else if (JSON.parse(sessionStorage.getItem('userData')).userRole === 'District') {
            setDistrictUser(true);
        }

        const type = { value: 1, label: 'Diwali Puja 2024' };
        setDonationTypeId(type);
    }, []);

    // const validate = () => {
    //     let isValid = true;
    //     const errs = {};
    //     if (!stateId) {
    //         isValid = false;
    //         errs[".state"] = "Please select state";
    //     }
    //     setErrors(errs);
    //     return isValid;
    // }

    const countryChangeHandler = (ev) => {
        setCountryId(ev);
        setStateData(null);
        setDistrictData(null);
        getStates(ev.value);
    }
    const stateChangeHandler = (ev) => {
        setStateId(ev);
        // setDistrictId(null);
        getDistricts(ev.value);
    }
    const districtChangeHandler = (ev) => {
        setDistrictId(ev);
    }
    const donationChangeHandler = (ev) => {
        setDonationTypeId(ev);
    }

    const handleChange = (event) => {
        setShowDetailsReport(false);
        setShowSummaryReport(false);
        setReportType(event.target.value);
    };

    function GetDonorDetailsReport() {
        const errs = {};
        fetch(
            service.GET_DONOR_DETAILS_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CountryId: countryId.value,
                    StateId: stateId.value ? stateId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0,
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowDetailsReport(true);
                    setReportDetailsData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
            });
    }

    function BankCollectionSummaryReport() {
        const errs = {};
        fetch(
            service.GET_BANK_COLLECTION_SUMMARY,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CountryId: countryId.value ? countryId.value : 0,
                    StateId: stateId.value ? stateId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0,
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowSummaryReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowSummaryReport(true);
                    setReportSummaryData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowSummaryReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowSummaryReport(false);
                }
            });
    }

    function CashCollectionSummaryReport() {
        const errs = {};
        fetch(
            service.GET_CASH_COLLECTION_SUMMARY,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CountryId: countryId.value ? countryId.value : 0,
                    StateId: stateId.value ? stateId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0,
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowSummaryReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowSummaryReport(true);
                    setReportSummaryData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowSummaryReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowSummaryReport(false);
                }
            });
    }

    function OnlineCollectionSummaryReport() {
        const errs = {};
        fetch(
            service.GET_ONLINE_COLLECTION_SUMMARY,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CountryId: countryId.value ? countryId.value : 0,
                    StateId: stateId.value ? stateId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0,
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowSummaryReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowSummaryReport(true);
                    setReportSummaryData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowSummaryReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowSummaryReport(false);
                }
            });
    }

    function QRCollectionSummaryReport() {
        const errs = {};
        fetch(
            service.GET_QR_CODE_SUMMARY,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CountryId: countryId.value ? countryId.value : 0,
                    StateId: stateId.value ? stateId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0,
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowSummaryReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowSummaryReport(true);
                    setReportSummaryData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowSummaryReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowSummaryReport(false);
                }
            });
    }

    function CollectionSummaryReport() {
        const errs = {};
        fetch(
            service.GET_COLLECTION_SUMMARY,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CountryId: countryId.value ? countryId.value : 0,
                    StateId: stateId.value ? stateId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0,
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowSummaryReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowSummaryReport(true);
                    setReportSummaryData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowSummaryReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowSummaryReport(false);
                }
            });

    }
    function BankCollectionDonorDetailsReport() {
        const errs = {};
        fetch(
            service.GET_BANK_COLLECTION_DONOR_DETAILS_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CountryId: countryId.value ? countryId.value : 0,
                    StateId: stateId.value ? stateId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0,
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowDetailsReport(true);
                    setReportDetailsData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
            });

    }
    function CashCollectionDonorDetailsReport() {
        const errs = {};
        fetch(
            service.GET_CASH_COLLECTION_DONOR_DETAILS_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CountryId: countryId.value ? countryId.value : 0,
                    StateId: stateId.value ? stateId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0,
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowDetailsReport(true);
                    setReportDetailsData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
            });
    }

    function OnlineCollectionDonorDetailsReport() {
        const errs = {};
        fetch(
            service.GET_ONLINE_COLLECTION_DONOR_DETAILS_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CountryId: countryId.value ? countryId.value : 0,
                    StateId: stateId.value ? stateId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0,
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowDetailsReport(true);
                    setReportDetailsData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
            });
    }
    function QRCollectionDonorDetailsReport() {
        const errs = {};
        fetch(
            service.GET_QR_CODE_DONOR_DETAILS_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CountryId: countryId.value ? countryId.value : 0,
                    StateId: stateId.value ? stateId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0,
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowDetailsReport(true);
                    setReportDetailsData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
            });

    }
    function ReconciledDonorDetailsReport() {
        const errs = {};
        fetch(
            service.GET_RECONCILED_DONOR_DETAILS_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CountryId: countryId.value ? countryId.value : 0,
                    StateId: stateId.value ? stateId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0,
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowDetailsReport(true);
                    setReportDetailsData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
            });
    }

    function NonReconciledDonorDetailsReport() {
        const errs = {};
        fetch(
            service.GET_NON_RECONCILED_DONOR_DETAILS_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CountryId: countryId.value ? countryId.value : 0,
                    StateId: stateId.value ? stateId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0,
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowDetailsReport(true);
                    setReportDetailsData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
            });
    }

    function AllocatedPassDonorDetailsReport() {
        const errs = {};
        fetch(
            service.GET_ALLOCATED_PASS_DONOR_DETAILS_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CountryId: countryId.value ? countryId.value : 0,
                    StateId: stateId.value ? stateId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0,
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowDetailsReport(true);
                    setReportDetailsData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
            });
    }

    function NonAllocatedPassDonorDetailsReport() {
        const errs = {};
        fetch(
            service.GET_NON_ALLOCATED_PASS_DONOR_DETAILS_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CountryId: countryId.value ? countryId.value : 0,
                    StateId: stateId.value ? stateId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0,
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowDetailsReport(true);
                    setReportDetailsData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
            });
    }

    function GetOneDayDonorDetailsReport() {
        const errs = {};
        fetch(
            service.GET_ONE_DAY_DONOR_DETAILS_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CountryId: countryId.value,
                    StateId: stateId.value ? stateId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0,
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowDetailsReport(true);
                    setReportDetailsData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
            });
    }
    const submitHandler = e => {
        setShowDetailsReport(false);
        setShowSummaryReport(false);
        e.preventDefault();
        setLoading(true);
        if (!reportType) {
            // eslint-disable-next-line
            alert("Please select report type");
        }
        if (reportType === "donordetails") {
            GetDonorDetailsReport();
        }
        else if (reportType === "banksummary") {
            BankCollectionSummaryReport();
        }
        else if (reportType === "cashsummary") {
            CashCollectionSummaryReport();
        }
        else if (reportType === "onlinesummary") {
            OnlineCollectionSummaryReport();
        }
        else if (reportType === "qrsummary") {
            QRCollectionSummaryReport();
        }
        else if (reportType === "collsummary") {
            CollectionSummaryReport();
        }
        else if (reportType === "bankdonordetails") {
            BankCollectionDonorDetailsReport();
        }
        else if (reportType === "cashdonordetails") {
            CashCollectionDonorDetailsReport();
        }
        else if (reportType === "onlinedonordetails") {
            OnlineCollectionDonorDetailsReport();
        }
        else if (reportType === "qrdonordetails") {
            QRCollectionDonorDetailsReport();
        }
        else if (reportType === "reconcileddonordetails") {
            ReconciledDonorDetailsReport();
        }
        else if (reportType === "nonreconcileddonordetails") {
            NonReconciledDonorDetailsReport();
        }
        else if (reportType === "allocatedpassdonordetails") {
            AllocatedPassDonorDetailsReport();
        }
        else if (reportType === "nonallocatedpassdonordetails") {
            NonAllocatedPassDonorDetailsReport();
        }
        else if (reportType === "onedaydonordetails") {
            GetOneDayDonorDetailsReport();
        }
        setLoading(false);
    }

    // const printDocument = (val) => {
    //     let input = null;
    //     if (val === "details") {
    //         input = document.getElementById('dvDetailReport');
    //     } else if (val === "summary") {
    //         input = document.getElementById('dvSummaryReport');
    //     }
    //     html2canvas(input, { scale: "2" })
    //         .then((canvas) => {
    //             const imgData = canvas.toDataURL('image/png');
    //             const imgWidth = 190;
    //             const pageHeight = 290;
    //             const imgHeight = (canvas.height * imgWidth) / canvas.width;
    //             let heightLeft = imgHeight;
    //             // eslint-disable-next-line new-cap
    //             const doc = new jsPDF('pt', 'mm');
    //             let position = 0;
    //             doc.addImage(imgData, 'PNG', 10, 0, imgWidth, imgHeight + 25);
    //             heightLeft -= pageHeight;
    //             while (heightLeft >= 0) {
    //                 position = heightLeft - imgHeight;
    //                 doc.addPage();
    //                 doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight + 25);
    //                 heightLeft -= pageHeight;
    //             }
    //             doc.save('ReportData.pdf');
    //         });
    // }
    const BpIcon = styled('span')(({ theme }) => ({
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow:
            theme.palette.mode === 'dark'
                ? '0 0 0 1px rgb(16 22 26 / 40%)'
                : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
        backgroundImage:
            theme.palette.mode === 'dark'
                ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
                : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background:
                theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
        },
    }));
    const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    });

    const ShowDetailsReport = React.memo(() => (
        <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center' }}>
            <Table id="dvDetailReport">
                <TableBody>
                    <TableRow style={{ backgroundColor: 'lightgrey' }}>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Name</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>PAN</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Gender</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Mobile No</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>State</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>District</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Center</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Donor Type</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Senior Citizen</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Contribution</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Payment Mode</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Payment Date</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>UTR Code</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Bank</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Coupon No</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Group Leader</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Reconciled</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Receipt No.</TableCell>
                    </TableRow>
                    {reportDetailsData.map((row) => (
                        <TableRow
                            key={row.donorId}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell style={{ fontSize: '14px' }}>{row.firstName} {row.middleName} {row.lastName}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.pan}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.gender}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.mobileNo}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.state}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.district}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.center}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.donorType}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.isSeniorCitizen ? 'Yes' : 'No'}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.contribution}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.paymentMode}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{moment(row.paymentDate).format("DD/MM/YYYY")}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.utrCode}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.bank}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.couponNo}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.groupLeaderName}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.isReconciled ? 'Yes' : 'No'}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.paymentId}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

    ));

    const ShowSummaryReport = React.memo(() => (
        <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center' }}>
            <Table id="dvSummaryReport">
                <TableBody>
                    <TableRow style={{ backgroundColor: 'lightgrey' }}>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>State</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>District</TableCell>
                        <TableCell align="center" colSpan={3} style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Adult</TableCell>
                        <TableCell align="center" colSpan={3} style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Yuvashakti</TableCell>
                        <TableCell align="center" colSpan={3} style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Balshakti</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Total Count</TableCell>
                        <TableCell align="center" colSpan={3} style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Collection</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Total Donation</TableCell>
                    </TableRow>
                    <TableRow style={{ backgroundColor: 'lightgrey' }}>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }} />
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }} />
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Male</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Female</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Total</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Male</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Female</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Total</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Male</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Female</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Total</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }} />
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Adult</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Yuvashakti</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Balshakti</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }} />
                    </TableRow>
                    {reportSummaryData.map((row) => (
                        <TableRow
                            key={row.donorId}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.state}</TableCell>
                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.district}</TableCell>
                            <TableCell align="right" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.adultMaleCount}</TableCell>
                            <TableCell align="right" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.adultFemaleCount}</TableCell>
                            <TableCell align="right" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.adultTotalCount}</TableCell>
                            <TableCell align="right" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.yuvaMaleCount}</TableCell>
                            <TableCell align="right" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.yuvaFemaleCount}</TableCell>
                            <TableCell align="right" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.yuvaTotalCount}</TableCell>
                            <TableCell align="right" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.balMaleCount}</TableCell>
                            <TableCell align="right" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.balFemaleCount}</TableCell>
                            <TableCell align="right" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.balTotalCount}</TableCell>
                            <TableCell align="right" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.totalCount}</TableCell>
                            <TableCell align="right" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.adultTotalAmount}</TableCell>
                            <TableCell align="right" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.yuvaTotalAmount}</TableCell>
                            <TableCell align="right" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.balTotalAmount}</TableCell>
                            <TableCell align="right" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.totalAmount}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    ));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Reports
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <br />
                                <TableContainer component={Paper} style={{ width: '98%', alignSelf: 'center' }}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell style={{ fontSize: '14px', fontWeight: 'bold', width: '25%' }}>
                                                    Donor Details
                                                    <Radio
                                                        checked={reportType === 'donordetails'}
                                                        onChange={handleChange}
                                                        value="donordetails"
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                        title="Donor Details"
                                                        sx={{
                                                            '&:hover': {
                                                                bgcolor: 'transparent',
                                                            },
                                                        }}
                                                        disableRipple
                                                        color="default"
                                                        checkedIcon={<BpCheckedIcon />}
                                                        icon={<BpIcon />}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                    Bank Collection Summary
                                                    <Radio
                                                        checked={reportType === 'banksummary'}
                                                        onChange={handleChange}
                                                        value="banksummary"
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': 'B' }}
                                                        title="Bank Collection Summary"
                                                        sx={{
                                                            '&:hover': {
                                                                bgcolor: 'transparent',
                                                            },
                                                        }}
                                                        disableRipple
                                                        color="default"
                                                        checkedIcon={<BpCheckedIcon />}
                                                        icon={<BpIcon />}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                    Cash Collection Summary
                                                    <Radio
                                                        checked={reportType === 'cashsummary'}
                                                        onChange={handleChange}
                                                        value="cashsummary"
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': 'B' }}
                                                        title="Cash Collection Summary"
                                                        sx={{
                                                            '&:hover': {
                                                                bgcolor: 'transparent',
                                                            },
                                                        }}
                                                        disableRipple
                                                        color="default"
                                                        checkedIcon={<BpCheckedIcon />}
                                                        icon={<BpIcon />}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                    Online Collection Summary
                                                    <Radio
                                                        checked={reportType === 'onlinesummary'}
                                                        onChange={handleChange}
                                                        value="onlinesummary"
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': 'B' }}
                                                        title="Online Collection Summary"
                                                        sx={{
                                                            '&:hover': {
                                                                bgcolor: 'transparent',
                                                            },
                                                        }}
                                                        disableRipple
                                                        color="default"
                                                        checkedIcon={<BpCheckedIcon />}
                                                        icon={<BpIcon />}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                    Collection Summary
                                                    <Radio
                                                        checked={reportType === 'collsummary'}
                                                        onChange={handleChange}
                                                        value="collsummary"
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': 'B' }}
                                                        title="Collection Summary"
                                                        sx={{
                                                            '&:hover': {
                                                                bgcolor: 'transparent',
                                                            },
                                                        }}
                                                        disableRipple
                                                        color="default"
                                                        checkedIcon={<BpCheckedIcon />}
                                                        icon={<BpIcon />}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                    Bank Collection Donor Details
                                                    <Radio
                                                        checked={reportType === 'bankdonordetails'}
                                                        onChange={handleChange}
                                                        value="bankdonordetails"
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                        title="Bank Collection Donor Details"
                                                        sx={{
                                                            '&:hover': {
                                                                bgcolor: 'transparent',
                                                            },
                                                        }}
                                                        disableRipple
                                                        color="default"
                                                        checkedIcon={<BpCheckedIcon />}
                                                        icon={<BpIcon />}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                    Cash Collection Donor Details
                                                    <Radio
                                                        checked={reportType === 'cashdonordetails'}
                                                        onChange={handleChange}
                                                        value="cashdonordetails"
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': 'B' }}
                                                        title="Cash Collection Donor Details"
                                                        sx={{
                                                            '&:hover': {
                                                                bgcolor: 'transparent',
                                                            },
                                                        }}
                                                        disableRipple
                                                        color="default"
                                                        checkedIcon={<BpCheckedIcon />}
                                                        icon={<BpIcon />}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                    Online Collection Donor Details
                                                    <Radio
                                                        checked={reportType === 'onlinedonordetails'}
                                                        onChange={handleChange}
                                                        value="onlinedonordetails"
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': 'B' }}
                                                        title="Online Collection Donor Details"
                                                        sx={{
                                                            '&:hover': {
                                                                bgcolor: 'transparent',
                                                            },
                                                        }}
                                                        disableRipple
                                                        color="default"
                                                        checkedIcon={<BpCheckedIcon />}
                                                        icon={<BpIcon />}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                    QR Code Collection Summary
                                                    <Radio
                                                        checked={reportType === 'qrsummary'}
                                                        onChange={handleChange}
                                                        value="qrsummary"
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': 'B' }}
                                                        title="QR Code Collection Summary"
                                                        sx={{
                                                            '&:hover': {
                                                                bgcolor: 'transparent',
                                                            },
                                                        }}
                                                        disableRipple
                                                        color="default"
                                                        checkedIcon={<BpCheckedIcon />}
                                                        icon={<BpIcon />}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                    QR Code Collection Donor Details
                                                    <Radio
                                                        checked={reportType === 'qrdonordetails'}
                                                        onChange={handleChange}
                                                        value="qrdonordetails"
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                        title="QR Code Collection Donor Details"
                                                        sx={{
                                                            '&:hover': {
                                                                bgcolor: 'transparent',
                                                            },
                                                        }}
                                                        disableRipple
                                                        color="default"
                                                        checkedIcon={<BpCheckedIcon />}
                                                        icon={<BpIcon />}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                    Reconciled Donor Details
                                                    <Radio
                                                        checked={reportType === 'reconcileddonordetails'}
                                                        onChange={handleChange}
                                                        value="reconcileddonordetails"
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': 'B' }}
                                                        title="Reconciled Donor Details"
                                                        sx={{
                                                            '&:hover': {
                                                                bgcolor: 'transparent',
                                                            },
                                                        }}
                                                        disableRipple
                                                        color="default"
                                                        checkedIcon={<BpCheckedIcon />}
                                                        icon={<BpIcon />}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                    Non Reconciled Donor Details
                                                    <Radio
                                                        checked={reportType === 'nonreconcileddonordetails'}
                                                        onChange={handleChange}
                                                        value="nonreconcileddonordetails"
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                        title="Non Reconciled Donor Details"
                                                        sx={{
                                                            '&:hover': {
                                                                bgcolor: 'transparent',
                                                            },
                                                        }}
                                                        disableRipple
                                                        color="default"
                                                        checkedIcon={<BpCheckedIcon />}
                                                        icon={<BpIcon />}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                    Allocated Pass Donor Details
                                                    <Radio
                                                        checked={reportType === 'allocatedpassdonordetails'}
                                                        onChange={handleChange}
                                                        value="allocatedpassdonordetails"
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': 'B' }}
                                                        title="Allocated Pass Donor Details"
                                                        sx={{
                                                            '&:hover': {
                                                                bgcolor: 'transparent',
                                                            },
                                                        }}
                                                        disableRipple
                                                        color="default"
                                                        checkedIcon={<BpCheckedIcon />}
                                                        icon={<BpIcon />}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                    Non Allocated Pass Donor Details
                                                    <Radio
                                                        checked={reportType === 'nonallocatedpassdonordetails'}
                                                        onChange={handleChange}
                                                        value="nonallocatedpassdonordetails"
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                        title="Non Allocated Pass Donor Details"
                                                        sx={{
                                                            '&:hover': {
                                                                bgcolor: 'transparent',
                                                            },
                                                        }}
                                                        disableRipple
                                                        color="default"
                                                        checkedIcon={<BpCheckedIcon />}
                                                        icon={<BpIcon />}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                    One Day Donor Details
                                                    <Radio
                                                        checked={reportType === 'onedaydonordetails'}
                                                        onChange={handleChange}
                                                        value="onedaydonordetails"
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                        title="One Day Donor Details"
                                                        sx={{
                                                            '&:hover': {
                                                                bgcolor: 'transparent',
                                                            },
                                                        }}
                                                        disableRipple
                                                        color="default"
                                                        checkedIcon={<BpCheckedIcon />}
                                                        icon={<BpIcon />}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ width: '20%', fontSize: '14px' }}>
                                                    <Dropdown menuClassName='myMenuClassName' id="donationType"
                                                        name="donationType"
                                                        options={donationTypeData}
                                                        value={donationTypeId}
                                                        onChange={donationChangeHandler}
                                                        label="Donation Type"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".donationType"]}</div>
                                                </TableCell>
                                                <TableCell style={{ width: '25%', fontSize: '14px' }}>
                                                    <Dropdown menuClassName='myMenuClassName' id="country"
                                                        name="country"
                                                        options={countryData}
                                                        value={countryId}
                                                        onChange={countryChangeHandler}
                                                        label="Country"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".country"]}</div>
                                                </TableCell>
                                                <TableCell style={{ fontSize: '14px' }}>
                                                    {/* <Dropdown menuClassName='myMenuClassName' id="state"
                                                        name="state"
                                                        options={stateData} 
                                                        value={stateId}
                                                        onChange={stateChangeHandler}
                                                    /> */}
                                                    <Select
                                                        options={stateData}
                                                        name="state"
                                                        value={stateId}
                                                        onChange={stateChangeHandler}
                                                        isSearchable
                                                        isDisabled={stateUser || districtUser}
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".state"]}</div>
                                                </TableCell>
                                                <TableCell style={{ fontSize: '14px' }}>
                                                    {/* <Dropdown menuClassName='myMenuClassName' id="district"
                                                        name="district"
                                                        options={districtData}
                                                        value={districtId}
                                                        onChange={districtChangeHandler}
                                                    /> */}
                                                    <Select className="basic-single"
                                                        options={districtData}
                                                        name="district"
                                                        value={districtId}
                                                        onChange={districtChangeHandler}
                                                        isSearchable
                                                        isDisabled={districtUser}
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".district"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    From Date
                                                    <DatePicker
                                                        id="fromDate"
                                                        selected={fromDate}
                                                        onChange={date => setFromDate(date)}
                                                        dateFormat="MM/dd/yyyy"
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    To Date
                                                    <DatePicker
                                                        id="toDate"
                                                        selected={toDate}
                                                        onChange={date => setToDate(date)}
                                                        dateFormat="MM/dd/yyyy"
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={4} align="center">
                                                    <MDBox mt={4} mb={1} textAlign="center">
                                                        {loading ?
                                                            <Circles
                                                                heigth="20"
                                                                width="20"
                                                                color='grey'
                                                                ariaLabel='loading'
                                                            />

                                                            :
                                                            <MDButton color="info" onClick={submitHandler}>
                                                                Show
                                                            </MDButton>
                                                        }
                                                        {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                                    </MDBox>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TableContainer component={Paper} style={{ width: '98%', alignSelf: 'center' }}>
                                    <Table>
                                        <TableRow>
                                            <TableCell>
                                                {showDetailsReport ? <ShowDetailsReport /> : null}
                                                {showSummaryReport ? <ShowSummaryReport /> : null}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center">
                                                {showDetailsReport ?
                                                    <ReactHTMLTableToExcel table="dvDetailReport" className="info"
                                                        filename="ReportData" sheet="Sheet" buttonText="EXPORT TO EXCEL"
                                                    />
                                                    : null}
                                                {showSummaryReport ?
                                                    <ReactHTMLTableToExcel table="dvSummaryReport" className="info"
                                                        filename="ReportData" sheet="Sheet" buttonText="EXPORT TO EXCEL"
                                                    />
                                                    : null}
                                            </TableCell>
                                        </TableRow>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Reports;
