import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
// import MDAlert from "components/MDAlert";
import MDButton from 'components/MDButton';
import MDInput from "components/MDInput";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Oval } from 'react-loader-spinner';
import SearchIcon from '@mui/icons-material/Search';
import * as moment from 'moment';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import * as service from '../../../services/service';

function Receipt() {
    const [search, setSearch] = useState('');
    const [countryId, setCountryId] = useState('');
    const [countryData, setCountryData] = useState([]);
    const [stateId, setStateId] = useState('');
    const [stateData, setStateData] = useState([]);
    const [districtId, setDistrictId] = useState('');
    const [districtData, setDistrictData] = useState([]);
    const [data, setData] = useState([]);
    // const [error, setError] = useState(null);
    const [dataError, setDataError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [stateUser, setStateUser] = useState(false);
    const [districtUser, setDistrictUser] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [paymentMode, setPaymentMode] = useState('');
    const paymentModeData = [{ label: "Cash", value: "Cash" }, { label: "Bank Transfer", value: "Bank Transfer" },
    { label: "QR Code", value: "QR Code" }, { label: "Online", value: "Online" }];
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    const useremail = JSON.parse(sessionStorage.getItem('userData')).emailId;

    const navigate = useNavigate();

    const getCountries = () => {
        fetch(
            service.COUNTRY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.countryId, label: item.country }));
                setCountryData(data2);
            });
    };
    const getStates = (id) => {
        fetch(
            service.GET_STATE_BY_COUNTRY_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CountryId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.stateId, label: item.state }));
                const all = ({ value: "0", label: "All" });
                data2.unshift(all);
                setStateData(data2);
                setStateId(all);

                if (JSON.parse(sessionStorage.getItem('userData')).userRole !== 'Global') {
                    const st = data2.find(x => x.value === JSON.parse(sessionStorage.getItem('userData')).stateId);
                    setStateId(st);
                }
            });
    };

    const getDistricts = (id) => {
        fetch(
            service.GET_DISTRICT_BY_STATE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    StateId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.districtId, label: item.district }));

                const all = ({ value: "0", label: "All" });
                data2.unshift(all);
                setDistrictData(data2);
                setDistrictId(all);

                if (JSON.parse(sessionStorage.getItem('userData')).userRole !== 'Global') {
                    const dt = data2.find(x => x.value === JSON.parse(sessionStorage.getItem('userData')).districtId);
                    setDistrictId(dt);
                }
            });
    };

    const getDetails = () => {
        setLoading(true);
        console.log(paymentMode.value);
        fetch(
            service.DONORS_BY_SEARCH_FOR_RECEIPT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Search: search,
                    GroupLeaderId: 0,
                    CountryId: countryId.value,
                    StateId: stateId.value ? stateId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0,
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null,
                    PaymentMode: paymentMode.value
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result[0].message.messageType === "Failed") {
                    setDataError(result[0].message.message);
                    setData([]);
                }
                else {
                    setData(result);
                    setDataError('');
                }
            })
            .catch(err => {
                if (err) {
                    setDataError(err);
                };
            });
        setLoading(false);
    };

    const handleEdit = (groupLeaderId, paymentId) => {
        navigate(`/thanks/${groupLeaderId}/${paymentId}`);
    }

    useEffect(() => {
        getCountries();
        const def = { value: 1, label: 'India' };
        setCountryId(def);
        getStates(def.value);
        const mode = { label: 'Cash', value: 'Cash' };
        setPaymentMode(mode);
        if (JSON.parse(sessionStorage.getItem('userData')).userRole !== 'Global') {
            getDistricts(JSON.parse(sessionStorage.getItem('userData')).stateId);
        }

        if (JSON.parse(sessionStorage.getItem('userData')).userRole === 'State') {
            setStateUser(true);
        }
        else if (JSON.parse(sessionStorage.getItem('userData')).userRole === 'District') {
            setDistrictUser(true);
        }

    }, []);

    const countryChangeHandler = (ev) => {
        setCountryId(ev);
        setStateData(null);
        setDistrictData(null);
        getStates(ev.value);
    }
    const stateChangeHandler = (ev) => {
        setStateId(ev);
        // setDistrictId(null);
        getDistricts(ev.value);
    }
    const districtChangeHandler = (ev) => {
        setDistrictId(ev);
    }
    const paymentModeChangeHandler = (ev) => {
        setPaymentMode(ev);
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            {useremail === 't@t.com' || useremail === 'a@a.com' ?
                <MDBox pt={6} pb={3}>
                    <div style={{ fontSize: '14px', fontWeight: 'bold', height: '500px', color: 'red' }} align='center'>
                        Access Denied
                    </div>
                </MDBox>
                :
                <MDBox pt={6} pb={3}>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                >
                                    <MDTypography variant="h6" color="white">
                                        Donor Details
                                    </MDTypography>
                                </MDBox>
                                <br />
                                <Table>
                                    <TableRow>
                                        <TableCell style={{ width: '20%', fontSize: '14px' }}>
                                            <MDInput label="search" value={search}
                                                name="search"
                                                onChange={ev => setSearch(ev.target.value)}
                                                id="search"
                                                required
                                            />                                            
                                        </TableCell>
                                        <TableCell style={{ width: '25%', fontSize: '14px' }}>
                                            <Dropdown menuClassName='myMenuClassName' id="country"
                                                name="country"
                                                options={countryData}
                                                value={countryId}
                                                onChange={countryChangeHandler}
                                                label="Country"
                                            />                                            
                                        </TableCell>
                                        <TableCell style={{ fontSize: '14px' }}>
                                            <Select
                                                options={stateData}
                                                name="state"
                                                value={stateId}
                                                onChange={stateChangeHandler}
                                                isSearchable
                                                isDisabled={stateUser || districtUser}
                                                styles={selectStyles}
                                            />                                            
                                        </TableCell>
                                        <TableCell style={{ fontSize: '14px' }}>
                                            <Select className="basic-single"
                                                options={districtData}
                                                name="district"
                                                value={districtId}
                                                onChange={districtChangeHandler}
                                                isSearchable
                                                isDisabled={districtUser}
                                                styles={selectStyles}
                                            />                                            
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={4}>
                                        <div style={{ fontSize: '12px', color: 'green' }}>Search by FirstName, LastName, PAN or Receipt No</div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            From Date
                                            <DatePicker
                                                id="fromDate"
                                                selected={fromDate}
                                                onChange={date => setFromDate(date)}
                                                dateFormat="MM/dd/yyyy"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            To Date
                                            <DatePicker
                                                id="toDate"
                                                selected={toDate}
                                                onChange={date => setToDate(date)}
                                                dateFormat="MM/dd/yyyy"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Dropdown menuClassName='myMenuClassName' id="paymentMode"
                                                name="paymentMode"
                                                options={paymentModeData}
                                                value={paymentMode}
                                                onChange={paymentModeChangeHandler}
                                            />                                            
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>                                            
                                            <MDButton color="info" onClick={getDetails} endIcon={<SearchIcon />}>
                                                Search
                                            </MDButton>
                                        </TableCell>
                                    </TableRow>
                                </Table>                                
                                <br />
                                <Card style={{ alignItems: 'center' }}>
                                    <div style={{ fontSize: '12px', color: 'red' }}>{dataError}</div>
                                    {loading ? <Oval
                                        heigth="100"
                                        width="100"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <TableContainer component={Paper} style={{ width: '98%', alignSelf: 'center' }}>
                                            <Table aria-label="simple table">
                                                <TableRow style={{ backgroundColor: 'lightgrey' }}>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Name</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>District</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Gender</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Type</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Senior Citizen</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>PAN</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Mobile No</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Group Leader</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Action</TableCell>
                                                </TableRow>

                                                <TableBody>
                                                    {data.map((row) => (
                                                        <TableRow
                                                            key={row.donorId}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell style={{ fontSize: '14px' }}>{row.fullName}</TableCell>
                                                            <TableCell style={{ fontSize: '14px' }}>{row.district}</TableCell>
                                                            <TableCell style={{ fontSize: '14px' }}>{row.gender}</TableCell>
                                                            <TableCell style={{ fontSize: '14px' }}>{row.donorType}</TableCell>
                                                            <TableCell style={{ fontSize: '14px' }}>{row.isSeniorCitizen ? 'Yes' : 'No'}</TableCell>
                                                            <TableCell style={{ fontSize: '14px' }}>{row.pan}</TableCell>
                                                            <TableCell style={{ fontSize: '14px' }}>{row.mobileNo}</TableCell>
                                                            <TableCell style={{ fontSize: '14px' }}>{row.groupLeaderName}</TableCell>
                                                            <TableCell style={{ fontSize: '14px' }}><MDButton onClick={() => handleEdit(row.groupLeaderId, row.paymentId)}>Generate Receipt</MDButton></TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    }
                                </Card>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
            }
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default Receipt;