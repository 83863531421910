/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
 import Analytics from "layouts/dashboards/analytics";
// import Sales from "layouts/dashboards/sales";
// import ProfileOverview from "layouts/pages/profile/profile-overview";
// import AllProjects from "layouts/pages/profile/all-projects";
// import NewUser from "layouts/pages/users/new-user";
// import Settings from "layouts/pages/account/settings";
// import Billing from "layouts/pages/account/billing";
// import Invoice from "layouts/pages/account/invoice";
// import Timeline from "layouts/pages/projects/timeline";
// import PricingPage from "layouts/pages/pricing-page";
// import Widgets from "layouts/pages/widgets";
// import RTL from "layouts/pages/rtl";
// import Charts from "layouts/pages/charts";
// import Notifications from "layouts/pages/notifications";
// import Kanban from "layouts/applications/kanban";
// import Wizard from "layouts/applications/wizard";
// import DataTables from "layouts/applications/data-tables";
// import Calendar from "layouts/applications/calendar";

// import ProductPage from "layouts/ecommerce/products/product-page";

import SignInBasic from "layouts/authentication/sign-in/basic";
// import SignInCover from "layouts/authentication/sign-in/cover";
// import SignInIllustration from "layouts/authentication/sign-in/illustration";
// import SignUpCover from "layouts/authentication/sign-up/cover";
// import ResetCover from "layouts/authentication/reset-password/cover";

// import Bank from "layouts/masters/bank";
// import Branch from "layouts/masters/branch";
import Bank from "layouts/masters/bank";
// import Country from "layouts/masters/country";
import State from "layouts/masters/state";
import District from "layouts/masters/district";
import DonationType from "layouts/masters/donationtype";
import User from "layouts/masters/user";
import Donor from "layouts/donor";
import GroupLeader from "layouts/donor/GroupLeader";
import AddGroupBooking from "layouts/donor/GroupBooking";
import AddIndividualBooking from "layouts/donor/IndividualBooking";
import Reports from "layouts/reports";
import AllocateCoupons from "layouts/donor/AllocateCoupons";
import Reconcile from "layouts/donor/Reconcile";
import Receipt from "layouts/donor/Receipt/receipt";
// Material Dashboard 2 PRO React components
// import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
// import profilePicture from "assets/images/team-3.jpg";

const routes = [
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    route: "/dashboards/analytics",    
    component: <Analytics />,      
    noCollapse: true
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Bookings",
    key: "donor",
    icon: <Icon fontSize="medium">person</Icon>,
    collapse: [   
      {
        name: "Add Group Booking",
        key: "addgroupbooking",
        route: "/addgroupbooking",
        component: <AddGroupBooking />,  
      },  
      {
        name: "Add Individual Booking",
        key: "addindividualbooking",
        route: "/addindividualbooking",
        component: <AddIndividualBooking />,  
      },  
      {
        name: "View Donors",
        key: "viewdonor",
        route: "/donor/donor",
        component: <Donor />,  
      },  
      {
        name: "View Group Leaders",
        key: "groupleader",
        route: "/donor/groupleader",
        component: <GroupLeader />,    
      },  
    ]    
  },  
  { 
    type: "collapse",
    name: "Allocate Passes",
    key: "coupons",
    icon: <Icon fontSize="medium">AddCard</Icon>,  
    route: "/donor/coupons",
    component: <AllocateCoupons />,  
    noCollapse: true,         
  },
  { 
    type: "collapse",
    name: "Reconcilation",
    key: "reconcilation",
    icon: <Icon fontSize="medium">ConfirmationNumber</Icon>,  
    route: "/donor/reconcilation",
    component: <Reconcile />,  
    noCollapse: true,         
  },
  { 
    type: "collapse",
    name: "Receipt",
    key: "receipt",
    icon: <Icon fontSize="medium">ReceiptSharp</Icon>,  
    route: "/donor/receipt",
    component: <Receipt />,  
    noCollapse: true,         
  },
  { type: "divider", key: "divider-1" },
  { 
    type: "collapse",
    name: "Reports",
    key: "reports",
    icon: <Icon fontSize="medium">equalizer</Icon>,  
    route: "/donor/reports",
    component: <Reports />,  
    noCollapse: true,         
  },
  { type: "divider", key: "divider-2" },
  {
    type: "collapse",
    name: "Settings",
    key: "masters",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [   
      {
        name: "Bank",
        key: "bank",
        route: "/masters/bank",
        component: <Bank />,
      },  
      // {
      //   name: "Country",
      //   key: "country",
      //   route: "/masters/country",
      //   component: <Country />,
      // },
      {
        name: "State",
        key: "state",
        route: "/masters/state",
        component: <State />,
      },
      {
        name: "District",
        key: "district",
        route: "/masters/district",
        component: <District />,
      },
      {
        name: "Donation Type",
        key: "donationtype",
        route: "/masters/donationtype",
        component: <DonationType />,
      },
      {
        name: "Users",
        key: "user",
        route: "/masters/user",
        component: <User />,
      },
    ],
  },
  { type: "divider", key: "divider-3" },  
  // {
  //   type: "collapse",
  //   name: "Change Password",
  //   key: "changepassword",
  //   icon: <Icon fontSize="medium">password</Icon>,
  //   route: "/authentication/sign-in/basic",
  //   component: <SignInBasic />,         
  //   noCollapse: true
  // },
  {
    type: "collapse",
    name: "Logout",
    key: "logout",
    icon: <Icon fontSize="medium">logout</Icon>,
    route: "/authentication/sign-in/basic",
    component: <SignInBasic />,         
    noCollapse: true
  },    
];

export default routes;
