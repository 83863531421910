import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import * as moment from 'moment';
// import { jsPDF } from 'jspdf';
// import html2canvas from "html2canvas";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Oval } from 'react-loader-spinner';
import * as service from '../../../services/service';

function AllocateCoupons() {
    const [search, setSearch] = useState('');
    const [donorData, setDonorData] = useState([]);
    const [groupLeaderId, setGroupLeaderId] = useState('');
    const [GroupLeaderData, setGroupLeaderData] = useState([]);
    const [countryId, setCountryId] = useState('');
    const [countryData, setCountryData] = useState([]);
    const [stateId, setStateId] = useState('');
    const [stateData, setStateData] = useState([]);
    const [districtId, setDistrictId] = useState('');
    const [districtData, setDistrictData] = useState([]);
    const [dataError, setDataError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [stateUser, setStateUser] = useState(false);
    const [districtUser, setDistrictUser] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [couponList, setCouponList] = useState([]);
    const [error, setError] = useState(null);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    const useremail  = JSON.parse(sessionStorage.getItem('userData')).emailId;
    
    const getGroupLeaders = (stId, dtId) => {
        fetch(
            service.GROUP_LEADERS_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    StateId: stId,
                    DistrictId: dtId
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.groupLeaderId, label: item.firstName.concat(" ", item.middleName).concat(" ", item.lastName) }));
                setGroupLeaderData(data2);
            });
    };

    const getCountries = () => {
        fetch(
            service.COUNTRY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.countryId, label: item.country }));
                setCountryData(data2);
            });
    };
    const getStates = (id) => {
        fetch(
            service.GET_STATE_BY_COUNTRY_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CountryId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.stateId, label: item.state }));
                setStateData(data2);

                if (JSON.parse(sessionStorage.getItem('userData')).userRole !== 'Global') {
                    const st = data2.find(x => x.value === JSON.parse(sessionStorage.getItem('userData')).stateId);
                    setStateId(st);
                }
            });
    };

    const getDistricts = (id) => {
        fetch(
            service.GET_DISTRICT_BY_STATE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    StateId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.districtId, label: item.district }));
                setDistrictData(data2);

                if (JSON.parse(sessionStorage.getItem('userData')).userRole !== 'Global') {
                    const dt = data2.find(x => x.value === JSON.parse(sessionStorage.getItem('userData')).districtId);
                    setDistrictId(dt);
                }
            });
    };

    useEffect(() => {
        getGroupLeaders(JSON.parse(sessionStorage.getItem('userData')).stateId, JSON.parse(sessionStorage.getItem('userData')).districtId);
        getCountries();
        const def = { value: 1, label: 'India' };
        setCountryId(def);
        getStates(def.value);

        if (JSON.parse(sessionStorage.getItem('userData')).userRole !== 'Global') {
            getDistricts(JSON.parse(sessionStorage.getItem('userData')).stateId);
        }

        if (JSON.parse(sessionStorage.getItem('userData')).userRole === 'State') {
            setStateUser(true);
        }
        else if (JSON.parse(sessionStorage.getItem('userData')).userRole === 'District') {
            setDistrictUser(true);
        }
    }, []);

    const groupLeaderChangeHandler = (ev) => {
        setGroupLeaderId(ev);
    }
    const countryChangeHandler = (ev) => {
        setCountryId(ev);
        setStateData(null);
        setDistrictData(null);
        getStates(ev.value);
    }
    const stateChangeHandler = (ev) => {
        setStateId(ev);
        // setDistrictId(null);
        getDistricts(ev.value);
    }
    const districtChangeHandler = (ev) => {
        setDistrictId(ev);
    }

    const validate = () => {
        let isValid = true;
        if (!search && !groupLeaderId && !stateId && !districtId && !fromDate && !toDate) {
            isValid = false;
            setError("Please select search criteria to get the donors list");
        }
        else {
            setError('');
        }
        return isValid;
    }
    const getDonorList = e => {
        e.preventDefault();
        setLoading(true);
        if (validate()) {
            fetch(
                service.GET_DONORS_FOR_COUPONS,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        CountryId: countryId.value,
                        StateId: stateId.value ? stateId.value : 0,
                        DistrictId: districtId.value ? districtId.value : 0,
                        FromDate: fromDate,
                        ToDate: toDate,
                        Search: search,
                        GroupLeaderId: groupLeaderId.value,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result[0].message.messageType === "Failed") {
                        setDataError(result[0].message.message);
                        setDonorData([]);
                    }
                    else {
                        setDonorData(result);
                        setDataError('');
                    }
                })
                .catch(err => {
                    if (err) {
                        setDataError(err);
                    };
                });
        }
        setLoading(false);
    }

    // handle input change
    const handleInputChange = (donorId, e) => {
        const coup = { donorId, e };
        const list = [...couponList];
        const newList = list.filter(el => el.donorId !== donorId);
        newList.push(coup);        
        setCouponList(newList);
    };

    const submitHandler = e => {
        e.preventDefault();
        setLoading(true); 
        if (couponList.length > 0) {
            couponList.forEach(element => {  
               if(element.e.trim() !== ''){               
                fetch(
                    service.UPDATE_DONOR_COUPON,
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            DonorId: element.donorId,
                            CouponNo: element.e,
                            AddedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                            UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                        })
                    })
                    .then(res => res.json())
                    .then(result => {                        
                        if (result.message.messageType === 'Failed') {
                            // eslint-disable-next-line
                            alert(result.message.message);
                        }
                    });
                }
            });            
            // eslint-disable-next-line
            alert("Data Saved Successfully");
            window.location.reload();
        }
        setLoading(false);
    }


    return (
        <DashboardLayout>
            <DashboardNavbar />
            {useremail === 't@t.com' || useremail === 'a@a.com' ? 
            <MDBox pt={6} pb={3}>
                <div style={{fontSize: '14px', fontWeight: 'bold', height: '500px', color: 'red'}} align='center'>
                    Access Denied
                </div>
            </MDBox>
            :
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Allocate Coupons
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <br />
                                <TableContainer component={Paper} style={{ width: '98%', alignSelf: 'center' }}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell style={{ width: '25%', fontSize: '14px' }}>
                                                    <Dropdown menuClassName='myMenuClassName' id="country"
                                                        name="country"
                                                        options={countryData}
                                                        value={countryId}
                                                        onChange={countryChangeHandler}
                                                        label="Country"
                                                    />
                                                </TableCell>
                                                <TableCell style={{ fontSize: '14px' }}>
                                                    <Select
                                                        options={stateData}
                                                        name="state"
                                                        value={stateId}
                                                        onChange={stateChangeHandler}
                                                        isSearchable
                                                        isDisabled={stateUser || districtUser}
                                                        styles={selectStyles}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ fontSize: '14px' }}>
                                                    <Select className="basic-single"
                                                        options={districtData}
                                                        name="district"
                                                        value={districtId}
                                                        onChange={districtChangeHandler}
                                                        isSearchable
                                                        isDisabled={districtUser}
                                                        styles={selectStyles}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ fontSize: '14px' }}>
                                                    <Select
                                                        options={GroupLeaderData}
                                                        name="groupLeader"
                                                        value={groupLeaderId}
                                                        onChange={groupLeaderChangeHandler}
                                                        placeholder="Select Group Leader"
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>                                                
                                                <TableCell>
                                                    <MDInput label="search" value={search}
                                                        name="search"
                                                        onChange={ev => setSearch(ev.target.value)}
                                                        id="search"
                                                        required
                                                    />
                                                    <br />
                                                    <div style={{ fontSize: '12px', color: 'green' }}>Search by FirstName, LastName or District</div>
                                                </TableCell>
                                                <TableCell>
                                                    From Date
                                                    <DatePicker
                                                        id="fromDate"
                                                        selected={fromDate}
                                                        onChange={date => setFromDate(date)}
                                                        dateFormat="MM/dd/yyyy"
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    To Date
                                                    <DatePicker
                                                        id="toDate"
                                                        selected={toDate}
                                                        onChange={date => setToDate(date)}
                                                        dateFormat="MM/dd/yyyy"
                                                    />
                                                </TableCell>
                                                <TableCell colSpan={4} align="center">
                                                    <MDBox mt={4} mb={1} textAlign="center">
                                                        <MDButton color="info" onClick={getDonorList}>
                                                            Search
                                                        </MDButton>                                                        
                                                    </MDBox>
                                                </TableCell>
                                            </TableRow>            
                                            <div style={{ fontSize: '12px', color: 'red' }}>{error}</div>                                
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <div style={{ fontSize: '12px', color: 'red' }}>{dataError}</div>
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <TableContainer component={Paper} style={{ width: '98%', alignSelf: 'center' }}>
                                        <Table aria-label="simple table">
                                            <TableRow style={{ backgroundColor: 'lightgrey' }}>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Name</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>District</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Gender</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Type</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Senior Citizen</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>PAN</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Group Leader</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Pass No</TableCell>
                                            </TableRow>

                                            <TableBody>
                                                {donorData.map((row) => (
                                                    <TableRow
                                                        key={row.donorId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell style={{ fontSize: '14px' }}>{row.fullName}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.district}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.gender}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.donorType}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.isSeniorCitizen ? 'Yes' : 'No'}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.pan}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.groupLeaderName}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>
                                                            <MDInput label="Pass No" value={row.couponno}
                                                                name="couponno"
                                                                onChange={e => handleInputChange(row.donorId, e.target.value)}
                                                                id="couponno"                                                                
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                            <TableRow>
                                                <TableCell colSpan={8} align="center">
                                                    {loading ? <Oval
                                                        heigth="20"
                                                        width="20"
                                                        color='grey'
                                                        ariaLabel='loading'
                                                    /> :
                                                        <MDButton color="info" onClick={submitHandler}>
                                                            Save
                                                        </MDButton>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        </Table>
                                    </TableContainer>
                                }
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            }
            <Footer />
        </DashboardLayout>
    );
}

export default AllocateCoupons;