/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";
import MDButton from "components/MDButton";
// @mui icons
// import Icon from "@mui/material/Icon";
// Authentication layout components
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import BasicLayout from "layouts/authentication/components/BasicLayout";
// Images
// import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { jsPDF } from 'jspdf';
import html2canvas from "html2canvas";
import * as moment from 'moment';
import { Oval } from 'react-loader-spinner';
import * as service from '../../../services/service';

function Thanks() {
    const [groupLeaderData, setGroupLeaderData] = useState([]);
    const [paymentData, setPaymentData] = useState([]);
    const [donorData, setDonorData] = useState([]);
    // const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const params = useParams();

    const getGroupLeaderById = () => {
        setLoading(true);
        // const errs = {};      
        if (params.groupLeaderId > 0) {
            fetch(
                service.GET_GROUP_LEADER_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        GroupLeaderId: params.groupLeaderId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setGroupLeaderData(result);
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message.message);
                    }
                });
        }
        setLoading(false);
    };

    const getPaymentById = () => {
        setLoading(true);
        // const errs = {};      
        if (params.paymentId > 0) {
            fetch(
                service.GET_PAYMENT_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        PaymentId: params.paymentId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setPaymentData(result);
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message.message);
                    }
                });
        }
        setLoading(false);
    };

    const getDonorsByGrpId = () => {
        setLoading(true);
        // const errs = {};      
        if (params.paymentId > 0 && params.groupLeaderId > 0) {
            fetch(
                service.GET_DONOR_BY_GROUP_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        PaymentId: params.paymentId,
                        GroupLeaderId: params.groupLeaderId
                    })
                })
                .then(res => res.json())
                .then(result => {
                    setDonorData(result);
                    // if (result.message.messageType === 'Success') {
                    //     setDonorData(result);
                    // }
                    // else {
                    //     // eslint-disable-next-line
                    //     alert(result.message.message);
                    // }
                });
        }
        setLoading(false);
    };

    // const sendWhatsAppReceipt = () => {
    //     const input = document.getElementById('tblReceipt');
    //     html2canvas(input, { scale: "5" })
    //         .then((canvas) => {
    //             // const imgData = canvas.toDataURL('image/png');
    //             const imgData = canvas.toDataURL('image/jpeg', 0.3);
    //             const imgWidth = 190;
    //             const imgHeight = (canvas.height * imgWidth) / canvas.width;
    //             // eslint-disable-next-line new-cap
    //             const doc = new jsPDF('pt', 'mm', 'a4', true);
    //             // doc.addImage(imgData, 'PNG', 10, 0, imgWidth, imgHeight + 25);
    //             doc.addImage(imgData, "JPEG", 5, 0, imgWidth, imgHeight + 25, undefined, 'FAST');

    //             fetch(
    //                 'https://powerchat.in/api/send',
    //                 {
    //                     method: 'POST',
    //                     headers: {
    //                         'Accept': 'application/json',
    //                         'Content-Type': 'application/json'
    //                     },
    //                     body: JSON.stringify({
    //                         "number": groupLeaderData.mobileNo,
    //                         "type": "text",
    //                         "message": "DIWALI PUJA 2024 REGISTRATION RECEIPT (NAGPUR, MAHARASHTRA)",
    //                         "instance_id": "609ACF283XXXX",
    //                         "access_token": "667dd161c8f66"
    //                     })
    //                 })
    //         });
    // }
    useEffect(() => {
        getGroupLeaderById();
        getPaymentById();
        getDonorsByGrpId();
        // sendWhatsAppReceipt();
    }, []);

    const printDocument = () => {
        const input = document.getElementById('tblReceipt');
        html2canvas(input, { scale: "5" })
            .then((canvas) => {
                // const imgData = canvas.toDataURL('image/png');
                const imgData = canvas.toDataURL('image/jpeg', 0.3);
                const imgWidth = 190;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                // eslint-disable-next-line new-cap
                const doc = new jsPDF('pt', 'mm', 'a4', true);
                // doc.addImage(imgData, 'PNG', 10, 0, imgWidth, imgHeight + 25);
                doc.addImage(imgData, "JPEG", 5, 0, imgWidth, imgHeight + 25, undefined, 'FAST');
                doc.save('Receipt.pdf');
            });
    }
    const submitHandler = () => {
        navigate('/donor/receipt');
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div style={{ width: '100%' }}>
                <Card>
                    <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                        mx={2}
                        mt={-3}
                        p={2}
                        mb={1}
                        textAlign="center"
                    >
                        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                            Duplicate Receipt
                        </MDTypography>
                    </MDBox>
                    {loading ? <Oval
                        heigth="100"
                        width="100"
                        color='grey'
                        ariaLabel='loading'
                    /> :
                        <MDBox pt={4} pb={3} px={3}>
                            <MDBox component="form" role="form">
                                <Card style={{ alignItems: 'center' }}>
                                    <MDBox pt={4} pb={3} px={3} style={{ width: '80%' }}>
                                        <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center' }}>
                                            <Table id="tblReceipt" style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell colSpan={2} align="center" style={{ fontWeight: 'bold', fontSize: '16px', color: 'blue' }}>
                                                            H.H. SHRI MATAJI NIRMALA DEVI SAHAJAYOGA TRUST - PUJA MAHOSTAV
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell colSpan={2} align="center" style={{ fontWeight: 'bold', fontSize: '14px', backgroundColor: 'darkgray' }}>
                                                            DIWALI PUJA 2024 REGISTRATION RECEIPT (NAGPUR, MAHARASHTRA)
                                                            <br />
                                                            (Duplicate Receipt)
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ width: '50%', fontWeight: 'bold', fontSize: '14px' }}>
                                                            Receipt No. : {paymentData.paymentId}
                                                        </TableCell>
                                                        <TableCell align="right" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                            Receipt Date: {moment(paymentData.paymentDate).format("DD/MM/YYYY")}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell colSpan={2} style={{ fontSize: '14px' }}>
                                                            Received with thanks a sum of <strong> Rs {paymentData.contribution}</strong>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell colSpan={2}>
                                                            <Table style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                <TableRow>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        From :
                                                                    </TableCell>
                                                                    <TableCell colSpan={7} style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {groupLeaderData.fullName}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        District :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {groupLeaderData.district}
                                                                    </TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        State :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {groupLeaderData.state}
                                                                    </TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        Country :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {groupLeaderData.country}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        PAN :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {groupLeaderData.pan}
                                                                    </TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        Contact No. :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {groupLeaderData.mobileNo}
                                                                    </TableCell>
                                                                    <TableCell colSpan={4} style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }} />
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell style={{ width: '15%', fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        Payment Mode :
                                                                    </TableCell>
                                                                    <TableCell style={{ width: '15%', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {paymentData.paymentMode}
                                                                    </TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        Payment Id :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {paymentData.razorPaymentId}
                                                                    </TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        Towards :
                                                                    </TableCell>
                                                                    <TableCell colSpan={5} style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {paymentData.donationTypeName}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        Rs. :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {paymentData.contribution}
                                                                    </TableCell>
                                                                    {groupLeaderData.registrationType === 'IndividualBySelf' ?
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Type :
                                                                        </TableCell> : null}
                                                                    {groupLeaderData.registrationType === 'IndividualBySelf' ?
                                                                        <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {groupLeaderData.donorType}
                                                                        </TableCell> : null}
                                                                    {groupLeaderData.registrationType === 'IndividualBySelf' ?

                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Senior Citizen :
                                                                        </TableCell> : null}
                                                                    {groupLeaderData.registrationType === 'IndividualBySelf' ?
                                                                        <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {groupLeaderData.isSeniorCitizen ? 'Yes' : 'No'}
                                                                        </TableCell> : null}
                                                                </TableRow>
                                                            </Table>
                                                        </TableCell>
                                                    </TableRow>
                                                    {groupLeaderData.registrationType === 'GroupLeaderBySelf' || groupLeaderData.registrationType === 'GroupLeaderByAdmin' ?
                                                        <TableRow>
                                                            <TableCell colSpan={2}>
                                                                <Table style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                    <TableRow>
                                                                        <TableCell align="center" colSpan={10} style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Member Details
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    {donorData.map((row) => (
                                                                        <TableRow key={row.donorId}
                                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Member Name :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {row.fullName}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                PAN :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {row.pan}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Type :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {row.donorType}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Senior Citizen :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {row.isSeniorCitizen ? 'Yes' : 'No'}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Contribution :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {row.contribution}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </Table>
                                                            </TableCell>
                                                        </TableRow>
                                                        : null
                                                    }
                                                    <TableRow>
                                                        <TableCell colSpan={2} style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            Important Note : Please carry this receipt at the registration counter (Nagpur) while collecting the puja passes.
                                                            <br />
                                                            This is subject to the actual receipt of the payment in puja mahostav bank account.
                                                            <br />
                                                            Please contact on 9623239694 for any queries related to online registration.
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </MDBox>
                                    <MDBox mt={4} mb={1} textAlign="center">
                                        <Table>
                                            <TableRow>
                                                <TableCell>
                                                    <MDButton color="info" onClick={() => printDocument()}>
                                                        Download
                                                    </MDButton>
                                                </TableCell>
                                                <TableCell>
                                                    <MDButton color="secondary" onClick={submitHandler}>
                                                        Back
                                                    </MDButton>
                                                </TableCell>
                                            </TableRow>
                                        </Table>
                                    </MDBox>
                                </Card>
                            </MDBox>
                        </MDBox>
                    }
                </Card>
            </div>
        </DashboardLayout>
    );
}

export default Thanks;
