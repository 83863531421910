
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from 'moment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import * as service from '../../../../services/service';

function AddEditDonationType() {
    const [state, setState] = useState({ name: '', address: '', adultcontri: '', yuvacontri: '', balcontri: '' });
    const [countryId, setCountryId] = useState('');
    const [countryData, setCountryData] = useState([]);
    const [stateId, setStateId] = useState('');
    const [stateData, setStateData] = useState([]);
    const [districtId, setDistrictId] = useState('');
    const [districtData, setDistrictData] = useState([]);
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [currency, setCurrency] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const currencyData = [{label: "INR", value: "INR"}];

    const navigate = useNavigate();
    const params = useParams();

    const getCountries = () => {
        fetch(
            service.COUNTRY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.countryId, label: item.country }));
                setCountryData(data2);
            });
    };
    const getStates = (id) => {
        fetch(
            service.GET_STATE_BY_COUNTRY_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CountryId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.stateId, label: item.state }));
                setStateData(data2);
            });
    };

    const getDistricts = (id) => {
        fetch(
            service.GET_DISTRICT_BY_STATE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    StateId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.districtId, label: item.district }));
                setDistrictData(data2);
            });
    };

    const getDetailsById = () => {
        setLoading(true);
        const errs = {};
        if (params.id > 0) {
            fetch(
                service.GET_DONATION_TYPES_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        DonationTypeId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setState({ ...state, name: result.name, address: result.address, adultcontri: result.adultContri,
                                    yuvacontri: result.yuvaContri, balcontri: result.balContri });
                                    
                        const conData = ({ value: result.countryId, label: result.country });
                        setCountryId(conData);

                        getStates(conData.value);

                        const stData = ({ value: result.stateId, label: result.state });
                        setStateId(stData);
                        
                        getDistricts(stData.value);
                        const disData = ({ value: result.districtId, label: result.district });
                        setDistrictId(disData);

                        const cur = ({ value: result.currency, label: result.currency });
                        setCurrency(cur);            
                        setFromDate(new Date(result.fromDate));
                        setToDate(new Date(result.toDate));
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }

                });
        }
        setLoading(false);
    };

    useEffect(() => {
        getCountries();  
        if (params.id > 0) {
            getDetailsById();
        }
        else{
            const def = {value: 1, label: 'India'};
            setCountryId(def);
            getStates(def.value);

            const cur = {label: 'INR', value: 'INR'};
            setCurrency(cur);
        }
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!state.name) {
            isValid = false;
            errs[".name"] = "Please enter name";
        }
        if (!stateId) {
            isValid = false;
            errs[".state"] = "Please select state";
        }
        if (!districtId) {
            isValid = false;
            errs[".district"] = "Please select district";
        }
        if (!fromDate) {
            isValid = false;
            errs[".fromDate"] = "Please enter from date";
        }
        if (!toDate) {
            isValid = false;
            errs[".toDate"] = "Please enter to date";
        }
        if (!state.adultcontri) {
            isValid = false;
            errs[".adultcontri"] = "Please enter adult contribution";
        }        
        if (!state.yuvacontri) {
            isValid = false;
            errs[".yuvacontri"] = "Please enter yuva contribution";
        }       
        if (!state.balcontri) {
            isValid = false;
            errs[".balcontri"] = "Please enter bal contribution";
        }
        
        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const countryChangeHandler = (ev) => {
        setCountryId(ev);   
        setStateId(null);   
        setDistrictId(null);
        getStates(ev.value);  
    }
    const stateChangeHandler = (ev) => {
        setStateId(ev);   
        setDistrictId(null);
        getDistricts(ev.value);  
    }
    const districtChangeHandler = (ev) => {
        setDistrictId(ev);     
    }

    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            fetch(
                service.ADD_UPDATE_DONATION_TYPE,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        DonationTypeId: params.id ? params.id : null,
                        Name: state.name,
                        Address: state.address,
                        CountryId: countryId.value,
                        StateId: stateId.value,
                        DistrictId: districtId.value,
                        FromDate: moment(fromDate).format("MM/DD/YYYY"),                              
                        ToDate: moment(fromDate).format("MM/DD/YYYY"),
                        AdultContri: state.adultcontri,
                        YuvaContri: state.yuvacontri,
                        BalContri: state.balcontri,
                        Currency: currency.value,                        
                        AddedBy: JSON.parse(sessionStorage.getItem('userData')).userId,                  
                        UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/masters/donationtype');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/masters/donationtype');
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });        
            }
            setLoading(false);
        }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Save Details
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                            <br />
                                <TableContainer component={Paper}  style={{width: '80%', height: '400px', alignSelf: 'center',}}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="Name" fullWidth value={state.name || ''}
                                                    name="name"
                                                    onChange={changeHandler}
                                                    id="name"
                                                    required />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".name"]}</div>
                                            </TableCell> 
                                            <TableCell>
                                                <MDInput label="Address" fullWidth value={state.address || ''}
                                                    name="address"
                                                    onChange={changeHandler}
                                                    id="address"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".address"]}</div>
                                            </TableCell>
                                        </TableRow>                                       
                                        <TableRow>
                                            <TableCell>
                                                From Date
                                                    <DatePicker
                                                        id="fromDate"
                                                        selected={fromDate}
                                                        onChange={date => setFromDate(date)}
                                                        dateFormat="MM/dd/yyyy"
                                                        tabIndex={0}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".fromDate"]}</div>
                                            </TableCell>
                                            <TableCell>
                                            To Date
                                                    <DatePicker
                                                        id="toDate"
                                                        selected={toDate}
                                                        onChange={date => setToDate(date)}
                                                        dateFormat="MM/dd/yyyy"
                                                        tabIndex={0}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".toDate"]}</div>
                                            </TableCell>                                           
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Dropdown menuClassName='myMenuClassName' id="country"
                                                    name="country"
                                                    options={countryData} 
                                                    value={countryId}
                                                    onChange={countryChangeHandler}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".country"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Dropdown menuClassName='myMenuClassName' id="state"
                                                    name="state"
                                                    options={stateData} placeholder="Select State"
                                                    value={stateId}
                                                    onChange={stateChangeHandler}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".state"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Dropdown menuClassName='myMenuClassName' id="district"
                                                    name="district"
                                                    options={districtData} placeholder="Select District"
                                                    value={districtId}
                                                    onChange={districtChangeHandler}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".district"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="Adult Contribution" fullWidth value={state.adultcontri || ''}
                                                    name="adultcontri"
                                                    onChange={changeHandler}
                                                    id="adultcontri"
                                                    required />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".adultcontri"]}</div>
                                            </TableCell> 
                                            <TableCell>
                                                <MDInput label="Yuva Contribution" fullWidth value={state.yuvacontri || ''}
                                                    name="yuvacontri"
                                                    onChange={changeHandler}
                                                    id="yuvacontri"
                                                    required
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".yuvacontri"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Bal Contribution" fullWidth value={state.balcontri || ''}
                                                    name="balcontri"
                                                    onChange={changeHandler}
                                                    id="balcontri"                                                    
                                                    required
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".balcontri"]}</div>
                                            </TableCell>
                                        </TableRow>    
                                        <TableRow>                                           
                                            <TableCell>
                                                <Dropdown menuClassName='myMenuClassName' id="currency"
                                                    name="currency"
                                                    options={currencyData} 
                                                    value={currency}
                                                    onChange={ev => setCurrency(ev)}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".currency"]}</div>
                                            </TableCell>                                                                                      
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                </TableContainer>
                                <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                    <MDButton color="info" onClick={submitHandler}>
                                    SAVE
                                </MDButton>
                                    }
                                    {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                </MDBox>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default AddEditDonationType;