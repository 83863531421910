// const baseURL = 'https://localhost:44379/api/';
 const baseURL = 'https://pujaapi.sahajayogaindia.org.in/api/';

export const LOGIN_URL = `${baseURL}Account/login`;
export const BANK_URL = `${baseURL}Masters/getbanks`;
export const GET_BANK_BY_ID = `${baseURL}Masters/getbankbyid`;
export const ADD_UPDATE_BANK = `${baseURL}Masters/addeditbank`;
export const COUNTRY_URL = `${baseURL}Masters/getcountries`;
export const GET_COUNTRY_BY_ID = `${baseURL}Masters/getcountrybyid`;
export const ADD_UPDATE_COUNTRY = `${baseURL}Masters/addeditcountry`;
export const STATE_URL = `${baseURL}Masters/getstates`;
export const GET_STATE_BY_ID = `${baseURL}Masters/getstatebyid`;
export const GET_STATE_BY_COUNTRY_ID = `${baseURL}Masters/getstatesbycountryid`;
export const ADD_UPDATE_STATE = `${baseURL}Masters/addeditstate`;
export const DISTRICT_URL = `${baseURL}Masters/getdistricts`;
export const GET_DISTRICT_BY_STATE_ID = `${baseURL}Masters/getdistrictsbystateid`;
export const GET_DISTRICT_BY_ID = `${baseURL}Masters/getdistrictbyid`;
export const ADD_UPDATE_DISTRICT = `${baseURL}Masters/addeditdistrict`;
export const DONATION_TYPE_URL = `${baseURL}Masters/getdonationtypes`;
export const GET_ACTIVE_DONATION_TYPES = `${baseURL}Masters/getactivedonationtypes`;
export const GET_DONATION_TYPES_BY_ID = `${baseURL}Masters/getdonationtypebyid`;
export const ADD_UPDATE_DONATION_TYPE = `${baseURL}Masters/addeditdonationtype`;
export const UPDATE_DONATION_TYPE_STATUS = `${baseURL}Masters/updatedonationtypestatus`;
export const USERS_URL = `${baseURL}Masters/getusers`;
export const GET_ACTIVE_USERS = `${baseURL}Masters/getactiveusers`;
export const GET_USER_BY_ID = `${baseURL}Masters/getuserbyid`;
export const ADD_UPDATE_USER = `${baseURL}Masters/addedituser`;
export const UPDATE_USER_STATUS = `${baseURL}Masters/updateuserstatus`;

export const GROUP_LEADERS_URL = `${baseURL}Donors/getgroupleaders`;
export const GROUP_LEADER_BY_SEARCH_URL = `${baseURL}Donors/getgroupleaderbysearch`;
export const GET_GROUP_LEADER_BY_ID = `${baseURL}Donors/getgroupleaderbyid`;
export const ADD_UPDATE_GROUP_LEADER = `${baseURL}Donors/addupdategroupleader`;
export const DONORS_BY_SEARCH_URL = `${baseURL}Donors/getdonorsbysearch`;
export const GET_DONOR_BY_ID = `${baseURL}Donors/getdonorbyid`;
export const ADD_UPDATE_DONOR = `${baseURL}Donors/addupdatedonor`;
export const ADD_DONOR_PAYMENT = `${baseURL}Donors/adddonorpayment`;
export const DONORS_BY_SEARCH_FOR_RECEIPT = `${baseURL}Donors/getdonorsbysearchforreceipt`;

export const GET_TOTAL_COUNT = `${baseURL}Donors/gettotalcounts`;
export const GET_STATE_WISE_COUNT = `${baseURL}Donors/getstatewisecounts`;
export const GET_MAHARASHTRA_DISTRICT_WISE_COUNT = `${baseURL}Donors/getmaharashtradistrictwisecounts`;
export const GET_DONOR_DETAILS_REPORT = `${baseURL}Donors/getdonordetailsreport`;
export const GET_BANK_COLLECTION_SUMMARY = `${baseURL}Donors/getbankcollectionsummaryreport`;
export const GET_CASH_COLLECTION_SUMMARY = `${baseURL}Donors/getcashcollectionsummaryreport`;
export const GET_ONLINE_COLLECTION_SUMMARY = `${baseURL}Donors/getonlinecollectionsummaryreport`;
export const GET_QR_CODE_SUMMARY = `${baseURL}Donors/getqrcodesummaryreport`;
export const GET_COLLECTION_SUMMARY = `${baseURL}Donors/getcollectionsummaryreport`;
export const GET_BANK_COLLECTION_DONOR_DETAILS_REPORT = `${baseURL}Donors/getbankcollectiondonordetailsreport`;
export const GET_CASH_COLLECTION_DONOR_DETAILS_REPORT = `${baseURL}Donors/getcashcollectiondonordetailsreport`;
export const GET_ONLINE_COLLECTION_DONOR_DETAILS_REPORT = `${baseURL}Donors/getonlinecollectiondonordetailsreport`;
export const GET_QR_CODE_DONOR_DETAILS_REPORT = `${baseURL}Donors/getqrcodedonordetailsreport`;
export const GET_RECONCILE_DASHBOARD = `${baseURL}Donors/getreconcileddashboard`;
export const GET_COUPONS_DASHBOARD = `${baseURL}Donors/getcouponsdashboard`;
export const GET_RECONCILED_DONOR_DETAILS_REPORT = `${baseURL}Donors/getreconcileddonordetailsreport`;
export const GET_NON_RECONCILED_DONOR_DETAILS_REPORT = `${baseURL}Donors/getnonreconcileddonordetailsreport`;
export const GET_ALLOCATED_PASS_DONOR_DETAILS_REPORT = `${baseURL}Donors/getallocatedpassdonordetailsreport`;
export const GET_NON_ALLOCATED_PASS_DONOR_DETAILS_REPORT = `${baseURL}Donors/getnonallocatedpassdonordetailsreport`;
export const GET_DONORS_FOR_COUPONS = `${baseURL}Donors/getdonorsforcoupons`;
export const GET_DONORS_FOR_RECONCILATION = `${baseURL}Donors/getdonorsforreconcilation`;
export const UPDATE_DONOR_COUPON = `${baseURL}Donors/updatedonorcoupon`;
export const UPDATE_DONOR_RECONCILATION = `${baseURL}Donors/updatedonorreconcilation`;
export const GET_ONE_DAY_DONOR_DETAILS_REPORT = `${baseURL}Donors/getonedaydonordetailsreport`;

export const CREATE_PAYMENT_ORDER = `${baseURL}Donors/createpaymentorder`;

export const GET_DONOR_BY_GROUP_ID = `${baseURL}Donors/getdonordetailsbygrpleaderid`;
export const GET_PAYMENT_BY_ID = `${baseURL}Donors/getpaymentdetailsbyid`;

// ************************************************************************************************

// export const LOGIN_URL = 'https://api.sahajayogaindia.org.in/api/Account/login`;
// export const BANK_URL = 'https://api.sahajayogaindia.org.in/api/Masters/getbanks`;
// export const GET_BANK_BY_ID = 'https://api.sahajayogaindia.org.in/api/Masters/getbankbyid`;
// export const ADD_UPDATE_BANK = 'https://api.sahajayogaindia.org.in/api/Masters/addeditbank`;
// export const COUNTRY_URL = 'https://api.sahajayogaindia.org.in/api/Masters/getcountries`;
// export const GET_COUNTRY_BY_ID = 'https://api.sahajayogaindia.org.in/api/Masters/getcountrybyid`;
// export const ADD_UPDATE_COUNTRY = 'https://api.sahajayogaindia.org.in/api/Masters/addeditcountry`;
// export const STATE_URL = 'https://api.sahajayogaindia.org.in/api/Masters/getstates`;
// export const GET_STATE_BY_ID = 'https://api.sahajayogaindia.org.in/api/Masters/getstatebyid`;
// export const GET_STATE_BY_COUNTRY_ID = 'https://api.sahajayogaindia.org.in/api/Masters/getstatesbycountryid`;
// export const ADD_UPDATE_STATE = 'https://api.sahajayogaindia.org.in/api/Masters/addeditstate`;
// export const DISTRICT_URL = 'https://api.sahajayogaindia.org.in/api/Masters/getdistricts`;
// export const GET_DISTRICT_BY_STATE_ID = 'https://api.sahajayogaindia.org.in/api/Masters/getdistrictsbystateid`;
// export const GET_DISTRICT_BY_ID = 'https://api.sahajayogaindia.org.in/api/Masters/getdistrictbyid`;
// export const ADD_UPDATE_DISTRICT = 'https://api.sahajayogaindia.org.in/api/Masters/addeditdistrict`;
// export const DONATION_TYPE_URL = 'https://api.sahajayogaindia.org.in/api/Masters/getdonationtypes`;
// export const GET_ACTIVE_DONATION_TYPES = 'https://api.sahajayogaindia.org.in/api/Masters/getactivedonationtypes`;
// export const GET_DONATION_TYPES_BY_ID = 'https://api.sahajayogaindia.org.in/api/Masters/getdonationtypebyid`;
// export const ADD_UPDATE_DONATION_TYPE = 'https://api.sahajayogaindia.org.in/api/Masters/addeditdonationtype`;
// export const UPDATE_DONATION_TYPE_STATUS = 'https://api.sahajayogaindia.org.in/api/Masters/updatedonationtypestatus`;
// export const USERS_URL = 'https://api.sahajayogaindia.org.in/api/Masters/getusers`;
// export const GET_ACTIVE_USERS = 'https://api.sahajayogaindia.org.in/api/Masters/getactiveusers`;
// export const GET_USER_BY_ID = 'https://api.sahajayogaindia.org.in/api/Masters/getuserbyid`;
// export const ADD_UPDATE_USER = 'https://api.sahajayogaindia.org.in/api/Masters/addedituser`;
// export const UPDATE_USER_STATUS = 'https://api.sahajayogaindia.org.in/api/Masters/updateuserstatus`;

// export const GROUP_LEADERS_URL = 'https://api.sahajayogaindia.org.in/api/Donors/getgroupleaders`;
// export const GROUP_LEADER_BY_SEARCH_URL = 'https://api.sahajayogaindia.org.in/api/Donors/getgroupleaderbysearch`;
// export const GET_GROUP_LEADER_BY_ID = 'https://api.sahajayogaindia.org.in/api/Donors/getgroupleaderbyid`;
// export const ADD_UPDATE_GROUP_LEADER = 'https://api.sahajayogaindia.org.in/api/Donors/addupdategroupleader`;
// export const DONORS_BY_SEARCH_URL = 'https://api.sahajayogaindia.org.in/api/Donors/getdonorsbysearch`;
// export const GET_DONOR_BY_ID = 'https://api.sahajayogaindia.org.in/api/Donors/getdonorbyid`;
// export const ADD_UPDATE_DONOR = 'https://api.sahajayogaindia.org.in/api/Donors/addupdatedonor`;
// export const ADD_DONOR_PAYMENT = 'https://api.sahajayogaindia.org.in/api/Donors/adddonorpayment`;
// export const DONORS_BY_SEARCH_FOR_RECEIPT = 'https://api.sahajayogaindia.org.in/api/Donors/getdonorsbysearchforreceipt`;

// export const GET_TOTAL_COUNT = 'https://api.sahajayogaindia.org.in/api/Donors/gettotalcounts`;
// export const GET_STATE_WISE_COUNT = 'https://api.sahajayogaindia.org.in/api/Donors/getstatewisecounts`;
// export const GET_MAHARASHTRA_DISTRICT_WISE_COUNT = 'https://api.sahajayogaindia.org.in/api/Donors/getmaharashtradistrictwisecounts`;
// export const GET_DONOR_DETAILS_REPORT = 'https://api.sahajayogaindia.org.in/api/Donors/getdonordetailsreport`;
// export const GET_BANK_COLLECTION_SUMMARY = 'https://api.sahajayogaindia.org.in/api/Donors/getbankcollectionsummaryreport`;
// export const GET_CASH_COLLECTION_SUMMARY = 'https://api.sahajayogaindia.org.in/api/Donors/getcashcollectionsummaryreport`;
// export const GET_ONLINE_COLLECTION_SUMMARY = 'https://api.sahajayogaindia.org.in/api/Donors/getonlinecollectionsummaryreport`;
// export const GET_QR_CODE_SUMMARY = 'https://api.sahajayogaindia.org.in/api/Donors/getqrcodesummaryreport`;
// export const GET_COLLECTION_SUMMARY = 'https://api.sahajayogaindia.org.in/api/Donors/getcollectionsummaryreport`;
// export const GET_BANK_COLLECTION_DONOR_DETAILS_REPORT = 'https://api.sahajayogaindia.org.in/api/Donors/getbankcollectiondonordetailsreport`;
// export const GET_CASH_COLLECTION_DONOR_DETAILS_REPORT = 'https://api.sahajayogaindia.org.in/api/Donors/getcashcollectiondonordetailsreport`;
// export const GET_ONLINE_COLLECTION_DONOR_DETAILS_REPORT = 'https://api.sahajayogaindia.org.in/api/Donors/getonlinecollectiondonordetailsreport`;
// export const GET_QR_CODE_DONOR_DETAILS_REPORT = 'https://api.sahajayogaindia.org.in/api/Donors/getqrcodedonordetailsreport`;
// export const GET_RECONCILE_DASHBOARD = 'https://api.sahajayogaindia.org.in/api/Donors/getreconcileddashboard`;
// export const GET_COUPONS_DASHBOARD = 'https://api.sahajayogaindia.org.in/api/Donors/getcouponsdashboard`;
// export const GET_RECONCILED_DONOR_DETAILS_REPORT = 'https://api.sahajayogaindia.org.in/api/Donors/getreconcileddonordetailsreport`;
// export const GET_NON_RECONCILED_DONOR_DETAILS_REPORT = 'https://api.sahajayogaindia.org.in/api/Donors/getnonreconcileddonordetailsreport`;
// export const GET_ALLOCATED_PASS_DONOR_DETAILS_REPORT = 'https://api.sahajayogaindia.org.in/api/Donors/getallocatedpassdonordetailsreport`;
// export const GET_NON_ALLOCATED_PASS_DONOR_DETAILS_REPORT = 'https://api.sahajayogaindia.org.in/api/Donors/getnonallocatedpassdonordetailsreport`;
// export const GET_DONORS_FOR_COUPONS = 'https://api.sahajayogaindia.org.in/api/Donors/getdonorsforcoupons`;
// export const GET_DONORS_FOR_RECONCILATION = 'https://api.sahajayogaindia.org.in/api/Donors/getdonorsforreconcilation`;
// export const UPDATE_DONOR_COUPON = 'https://api.sahajayogaindia.org.in/api/Donors/updatedonorcoupon`;
// export const UPDATE_DONOR_RECONCILATION = 'https://api.sahajayogaindia.org.in/api/Donors/updatedonorreconcilation`;
// export const GET_ONE_DAY_DONOR_DETAILS_REPORT = 'https://api.sahajayogaindia.org.in/api/Donors/getonedaydonordetailsreport`;

// export const CREATE_PAYMENT_ORDER = 'https://api.sahajayogaindia.org.in/api/Donors/createpaymentorder`;

// export const GET_DONOR_BY_GROUP_ID = 'https://api.sahajayogaindia.org.in/api/Donors/getdonordetailsbygrpleaderid`;
// export const GET_PAYMENT_BY_ID = 'https://api.sahajayogaindia.org.in/api/Donors/getpaymentdetailsbyid`;

export default LOGIN_URL;