/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
// import Tooltip from "@mui/material/Tooltip";
// import Icon from "@mui/material/Icon";
import { useEffect, useState } from "react";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
// import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
// import BookingCard from "examples/Cards/BookingCard";

// Anaytics dashboard components
// import SalesByCountry from "layouts/dashboards/analytics/components/SalesByCountry";

// Data
// import reportsBarChartData from "layouts/dashboards/analytics/data/reportsBarChartData";
// import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";

// Images
// import booking1 from "assets/images/products/product-1-min.jpg";
// import booking2 from "assets/images/products/product-2-min.jpg";
// import booking3 from "assets/images/products/product-3-min.jpg";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as service from '../../../services/service';

function Analytics() {
  const [countData, setCountData] = useState([]);  
  const [stateCountData, setStateCountData] = useState([]);
  const [districtCountData, setDistrictCountData] = useState([]);
  const [reconcileData, setReconcileData] = useState([]);
  const [couponData, setCouponData] = useState([]);
  const [bankCollectionData, setBankCollectionData] = useState([]);
  const [cashCollectionData, setCashCollectionData] = useState([]);
  const [onlineCollectionData, setOnlineCollectionData] = useState([]);
  const [qrCollectionData, setQRCollectionData] = useState([]);

  const getTotalCounts = () => {
    // setLoading(true);    
    fetch(
      service.GET_TOTAL_COUNT,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
      .then(result => {
        setCountData(result);
      })
      .catch(err => {
        if (err) {
          // setDataError(err);
        };
      });
    // setLoading(false);
  };

  const getStateWiseCounts = () => {
    // setLoading(true);    
    fetch(
      service.GET_STATE_WISE_COUNT,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
      .then(result => {
        setStateCountData(result);        
      })
      .catch(err => {
        if (err) {
          // setDataError(err);
        };
      });
    // setLoading(false);
  };

  const getMaharashtraDistrictWiseCounts = () => {
    // setLoading(true);    
    fetch(
      service.GET_MAHARASHTRA_DISTRICT_WISE_COUNT,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
      .then(result => {
        setDistrictCountData(result);               
      })
      .catch(err => {
        if (err) {
          // setDataError(err);
        };
      });
    // setLoading(false);
  };

  const getReconciledData = () => {
    // setLoading(true);    
    fetch(
      service.GET_RECONCILE_DASHBOARD,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
      .then(result => {
        setReconcileData(result);          
      })
      .catch(err => {
        if (err) {
          // setDataError(err);
        };
      });
    // setLoading(false);
  };

  const getCouponData = () => {
    // setLoading(true);    
    fetch(
      service.GET_COUPONS_DASHBOARD,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
      .then(result => {        
        setCouponData(result);          
      })
      .catch(err => {
        if (err) {
          // setDataError(err);
        };
      });
    // setLoading(false);
  };

  const getBankCollectionCounts = () => {    
    fetch(
      service.GET_BANK_COLLECTION_SUMMARY,
      {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              CountryId: 1,
              StateId: 0,
              DistrictId: 0,
              FromDate: null,
              ToDate: null
          })
      })
      .then(res => res.json())
      .then(result => {
        const newList = result.filter(el => el.state === 'Total'); 
        const array = [newList[0].totalAmount, newList[0].totalCount]             
        setBankCollectionData(array);                           
      })
      .catch(err => {
        if (err) {
          // setDataError(err);
        };
      });    
  };

  const getCashCollectionCounts = () => {    
    fetch(
      service.GET_CASH_COLLECTION_SUMMARY,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CountryId: 1,
                    StateId: 0,
                    DistrictId: 0,
                    FromDate: null,
                    ToDate: null
                })
            })
      .then(res => res.json())
      .then(result => {
        const newList = result.filter(el => el.state === 'Total');     
        const array = [newList[0].totalAmount, newList[0].totalCount]         
        setCashCollectionData(array);             
      })
      .catch(err => {
        if (err) {
          // setDataError(err);
        };
      });    
  };

  const getOnlineCollectionCounts = () => {    
    fetch(
      service.GET_ONLINE_COLLECTION_SUMMARY,
      {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              CountryId: 1,
              StateId: 0,
              DistrictId: 0,
              FromDate: null,
              ToDate: null
          })
      })
      .then(res => res.json())
      .then(result => {        
        const newList = result.filter(el => el.state === 'Total'); 
        const array = [newList[0].totalAmount, newList[0].totalCount]      
        setOnlineCollectionData(array);               
      })
      .catch(err => {
        if (err) {
          // setDataError(err);
        };
      });    
  };

  const getQRCollectionCounts = () => {    
    fetch(
      service.GET_QR_CODE_SUMMARY,
      {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              CountryId: 1,
              StateId: 0,
              DistrictId: 0,
              FromDate: null,
              ToDate: null
          })
      })
      .then(res => res.json())
      .then(result => {
        const newList = result.filter(el => el.state === 'Total'); 
        const array = [newList[0].totalAmount, newList[0].totalCount]             
        setQRCollectionData(array);                           
      })
      .catch(err => {
        if (err) {
          // setDataError(err);
        };
      });    
  };

  useEffect(() => {
    getTotalCounts();
    getStateWiseCounts();
    getMaharashtraDistrictWiseCounts();
    getReconciledData();
    getCouponData();
    getBankCollectionCounts();
    getCashCollectionCounts();
    getOnlineCollectionCounts();
    getQRCollectionCounts();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={2}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="people"
                  title="Adults"
                  count={countData.adultTotalCount}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="boy"
                  title="Yuvashakti"
                  count={countData.yuvaTotalCount}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="face"
                  title="Balshakti"
                  count={countData.balTotalCount}
                />
              </MDBox>
            </Grid><Grid item xs={12} md={6} lg={2}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="face"
                  title="Total Count"
                  count={countData.totalCount}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="money"
                  title="Contribution"
                  count={countData.contribution}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox py={3}>
          <MDBox mt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={3} align="center" style={{ fontSize: '12px', fontWeight: 'bold' }}>
                            Adults
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Male
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Female
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Total
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            {countData.adultMaleCount}
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            {countData.adultFemaleCount}
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px', fontWeight: 'bold' }}>
                            {countData.adultTotalCount}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={3} align="center" style={{ fontSize: '12px', fontWeight: 'bold' }}>
                            Yuvashakti
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Male
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Female
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Total
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            {countData.yuvaMaleCount}
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            {countData.yuvaFemaleCount}
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px', fontWeight: 'bold' }}>
                            {countData.yuvaTotalCount}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={3} align="center" style={{ fontSize: '12px', fontWeight: 'bold' }}>
                            Balshakti
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Male
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Female
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Total
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            {countData.balMaleCount}
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            {countData.balFemaleCount}
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px', fontWeight: 'bold' }}>
                            {countData.balTotalCount}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={4} align="center" style={{ fontSize: '12px', fontWeight: 'bold' }}>
                            Total
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Adults
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Yuvashakti
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Balshakti
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Total
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            {countData.adultTotalCount}
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            {countData.yuvaTotalCount}
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            {countData.balTotalCount}
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px', fontWeight: 'bold' }}>
                            {countData.totalCount}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        <MDBox py={3}>
          <MDBox mt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={2} align="center" style={{ fontSize: '12px', fontWeight: 'bold' }}>
                            Online Collection
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Total Count
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Total Collection
                          </TableCell>                         
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            {onlineCollectionData[1] ?  onlineCollectionData[1]  : 0}
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            {onlineCollectionData[0] ? onlineCollectionData[0] : 0}
                          </TableCell>                         
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={2} align="center" style={{ fontSize: '12px', fontWeight: 'bold' }}>
                            Bank Collection
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Total Count
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Total Collection
                          </TableCell>                         
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            {bankCollectionData[1] ? bankCollectionData[1] : 0}
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            {bankCollectionData[0] ? bankCollectionData[0] : 0}
                          </TableCell>                         
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={3} align="center" style={{ fontSize: '12px', fontWeight: 'bold' }}>
                            Cash Collection
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Total Count
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Total Collection
                          </TableCell>                         
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            {cashCollectionData[1] ? cashCollectionData[1] : 0}
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            {cashCollectionData[0] ? cashCollectionData[0] : 0}
                          </TableCell>                         
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </MDBox>
              </Grid>  
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={3} align="center" style={{ fontSize: '12px', fontWeight: 'bold' }}>
                            QR Code Collection
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Total Count
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Total Collection
                          </TableCell>                         
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            {qrCollectionData[1] ? qrCollectionData[1] : 0}
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            {qrCollectionData[0] ? qrCollectionData[0] : 0}
                          </TableCell>                         
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </MDBox>
              </Grid>            
            </Grid>
          </MDBox>
        </MDBox>
        <MDBox mt={2}>
          <Grid container spacing={3}>           
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="money"
                  title="Reconciled Amount"
                  count={reconcileData.reconciledAmount}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="money"
                  title="Non Reconciled Amount"
                  count={reconcileData.nonReconciledAmount}
                />
              </MDBox>
            </Grid>                   
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="money"
                  title="Allocated Coupons"
                  count={couponData.allocatedCoupons}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="money"
                  title="Not Allocated Coupons"
                  count={couponData.notAllocatedCoupons}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox py={3}>
          <MDBox mt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={3} align="center" style={{ fontSize: '12px', fontWeight: 'bold' }}>
                            One Day Registrations
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Male
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Female
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Total
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            {countData.oneDayMaleCount}
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            {countData.oneDayFemaleCount}
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px', fontWeight: 'bold' }}>
                            {countData.oneDayTotalCount}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        <br />
        <Grid>
          <div style={{ fontSize: '14px', fontWeight: 'bold',}}>State Wise Analysis</div>
          <MDBox mb={1.5}>
            <TableContainer component={Paper}>
              <Table style={{"borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid'}}>
                <TableRow>
                  <TableCell align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                    State
                  </TableCell>
                  <TableCell colSpan={3} align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                    Adults
                  </TableCell>
                  <TableCell colSpan={3} align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                    Yuvashakti
                  </TableCell>
                  <TableCell colSpan={3} align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                    Balshakti
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                    Total Count
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                    Total Donation
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid'  }} />
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                    Male
                  </TableCell>
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                    Female
                  </TableCell>
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                    Total
                  </TableCell>
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                    Male
                  </TableCell>
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                    Female
                  </TableCell>
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                    Total
                  </TableCell>
                  <TableCell align="right" style={{ fontSize: '12px' , "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid'}}>
                    Male
                  </TableCell>
                  <TableCell align="right" style={{ fontSize: '12px',"borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                    Female
                  </TableCell>
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                    Total
                  </TableCell>
                  <TableCell style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }} />
                  <TableCell style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }} />
                </TableRow>
                <TableBody>
                  {stateCountData.map((row) => (
                    <TableRow>
                      <TableCell align="center" style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                        {row.state}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                        {row.adultMaleCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                        {row.adultFemaleCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', fontWeight: 'bold',"borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                        {row.adultTotalCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                        {row.yuvaMaleCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px' , "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid'}}>
                        {row.yuvaFemaleCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', fontWeight: 'bold' , "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid'}}>
                        {row.yuvaTotalCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                        {row.balMaleCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                        {row.balFemaleCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                        {row.balTotalCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                        {row.totalCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                        {row.totalAmount}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </MDBox>
        </Grid>
        <br/>
        <Grid>
          <div style={{ fontSize: '14px', fontWeight: 'bold',}}>Districts In Maharashtra</div>
          <MDBox mb={1.5}>
            <TableContainer component={Paper}>
              <Table style={{"borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid'}}>
                <TableRow>
                  <TableCell align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                    Region
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                    District
                  </TableCell>
                  <TableCell colSpan={3} align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                    Adults
                  </TableCell>
                  <TableCell colSpan={3} align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                    Yuvashakti
                  </TableCell>
                  <TableCell colSpan={3} align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                    Balshakti
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                    Total Count
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                    Total Donation
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid'  }} />
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid'  }} />
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                    Male
                  </TableCell>
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                    Female
                  </TableCell>
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                    Total
                  </TableCell>
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                    Male
                  </TableCell>
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                    Female
                  </TableCell>
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                    Total
                  </TableCell>
                  <TableCell align="right" style={{ fontSize: '12px' , "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid'}}>
                    Male
                  </TableCell>
                  <TableCell align="right" style={{ fontSize: '12px',"borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                    Female
                  </TableCell>
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                    Total
                  </TableCell>
                  <TableCell style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }} />
                  <TableCell style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }} />
                </TableRow>
                <TableBody>
                  {districtCountData.map((row) => (
                    <TableRow>
                      <TableCell align="center" style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                        {row.region}
                      </TableCell>
                      <TableCell align="center" style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                        {row.district}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                        {row.adultMaleCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                        {row.adultFemaleCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', fontWeight: 'bold',"borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                        {row.adultTotalCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                        {row.yuvaMaleCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px' , "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid'}}>
                        {row.yuvaFemaleCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', fontWeight: 'bold' , "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid'}}>
                        {row.yuvaTotalCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                        {row.balMaleCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                        {row.balFemaleCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                        {row.balTotalCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                        {row.totalCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid' }}>
                        {row.totalAmount}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </MDBox>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Analytics;
