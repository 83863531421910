import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import MDAlert from "components/MDAlert";
import MDButton from 'components/MDButton';
import { Oval } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as moment from 'moment';
import * as service from '../../../services/service';

function DonationType() {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const useremail = JSON.parse(sessionStorage.getItem('userData')).emailId;
    const navigate = useNavigate();

    const getDetails = () => {
        fetch(
            service.DONATION_TYPE_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json())
            .then(result => {
                // if (result[0].message.messageType === 'Success') {
                setData(result);
                // }
                // else {
                //     setError(result[0].message.message);
                // }
            })
            .catch(err => {
                if (err) {
                    setError(err);
                };
            });
    };

    const handleEdit = (id) => {
        navigate(`/addeditdonationtype/${id}`);
    }

    useEffect(() => {
        setLoading(true);
        getDetails();
        setLoading(false);
    }, []);

    // const addNew = () => {
    //     navigate('/addeditdonationtype/0');
    // };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {error
                ? <MDAlert color="warning" dismissible>{error}</MDAlert>
                : null
            }
            {useremail === 't@t.com' || useremail === 'a@a.com' ?
                <MDBox pt={6} pb={3}>
                    <div style={{ fontSize: '14px', fontWeight: 'bold', height: '500px', color: 'red' }} align='center'>
                        Access Denied
                    </div>
                </MDBox>
                :
                <MDBox pt={6} pb={3}>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                >
                                    <MDTypography variant="h6" color="white">
                                        Donation Type
                                    </MDTypography>
                                </MDBox>
                                <br />
                                <Card style={{ alignItems: 'center' }}>
                                    {loading ? <Oval
                                        heigth="100"
                                        width="100"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <TableContainer component={Paper} style={{ width: '90%', alignSelf: 'center', }}>
                                            <Table aria-label="simple table">
                                                <TableRow style={{ backgroundColor: 'lightgrey' }}>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Address</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>From Date</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>To Date</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>AdultContri</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>YuvaContri</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>BalContri</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
                                                </TableRow>
                                                <TableBody>
                                                    {data.map((row) => (
                                                        <TableRow
                                                            key={row.donationTypeId}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row">{row.name}</TableCell>
                                                            <TableCell component="th" scope="row">{row.address}</TableCell>
                                                            <TableCell component="th" scope="row">{moment(row.fromDate).format("DD/MM/YYYY")}</TableCell>
                                                            <TableCell component="th" scope="row">{moment(row.toDate).format("DD/MM/YYYY")}</TableCell>
                                                            <TableCell component="th" scope="row">{row.adultContri}</TableCell>
                                                            <TableCell component="th" scope="row">{row.yuvaContri}</TableCell>
                                                            <TableCell component="th" scope="row">{row.balContri}</TableCell>
                                                            <TableCell><MDButton onClick={() => handleEdit(row.donationTypeId)}>Edit</MDButton></TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    }
                                </Card>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
            }
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default DonationType;