
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as service from '../../../services/service';

function EditDonor() {
    const [state, setState] = useState({ firstname: '', middlename: '', lastname: '', center: '', pan: '', couponno: ''});
    // const [countryId, setCountryId] = useState('');
    // const [countryData, setCountryData] = useState([]);
    // const [stateId, setStateId] = useState('');
    // const [stateData, setStateData] = useState([]);
    // const [districtId, setDistrictId] = useState('');
    // const [districtData, setDistrictData] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [gender, setGender] = useState('');
    const genderData = [{ label: "Male", value: "Male" }, { label: "Female", value: "Female" }];

    const navigate = useNavigate();
    const params = useParams();
    // const getCountries = () => {
    //     fetch(
    //         service.COUNTRY_URL,
    //         {
    //             method: 'POST',
    //             headers: {
    //                 'Accept': 'application/json',
    //                 'Content-Type': 'application/json'
    //             },
    //         })
    //         .then(res => res.json())
    //         .then(result => {
    //             const data2 = result.map((item) => ({ value: item.countryId, label: item.country }));
    //             setCountryData(data2);
    //         });
    // };
    // const getStates = (id) => {
    //     fetch(
    //         service.GET_STATE_BY_COUNTRY_ID,
    //         {
    //             method: 'POST',
    //             headers: {
    //                 'Accept': 'application/json',
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify({
    //                 CountryId: id,
    //             })
    //         })
    //         .then(res => res.json())
    //         .then(result => {
    //             const data2 = result.map((item) => ({ value: item.stateId, label: item.state }));
    //             setStateData(data2);
    //         });
    // };

    // const getDistricts = (id) => {
    //     fetch(
    //         service.GET_DISTRICT_BY_STATE_ID,
    //         {
    //             method: 'POST',
    //             headers: {
    //                 'Accept': 'application/json',
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify({
    //                 StateId: id,
    //             })
    //         })
    //         .then(res => res.json())
    //         .then(result => {
    //             const data2 = result.map((item) => ({ value: item.districtId, label: item.district }));
    //             setDistrictData(data2);
    //         });
    // };
    const getDetailsById = () => {
        setLoading(true);
        const errs = {};
        if (params.id > 0) {
            fetch(
                service.GET_DONOR_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        DonorId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setState({
                            ...state, firstname: result.firstName, middlename: result.middleName, lastname: result.lastName, 
                                center: result.center, pan: result.pan, couponno: result.couponNo
                        });
                        // const conData = ({ value: result.countryId, label: result.country });
                        // setCountryId(conData);
                        // getStates(conData.value);
                        // const stData = ({ value: result.stateId, label: result.state });
                        // setStateId(stData);
                        // getDistricts(stData.value);
                        // const disData = ({ value: result.districtId, label: result.district });
                        // setDistrictId(disData);
                        const gen = ({ value: result.gender, label: result.gender });
                        setGender(gen);
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    };

    useEffect(() => {
       // getCountries();
        if (params.id > 0) {
            getDetailsById();
        }
        else {
            // const def = { value: 1, label: 'India' };
            // setCountryId(def);
            // getStates(def.value);
            const gen = { label: 'Male', value: 'Male' };
            setGender(gen);
        }
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!state.firstname) {
            isValid = false;
            errs[".firstname"] = "Please enter first name";
        }
        if (!state.lastname) {
            isValid = false;
            errs[".lastname"] = "Please enter last name";
        }
        if (!state.pan) {
            isValid = false;
            errs[".pan"] = "Please enter PAN";
        }
        // if (!stateId) {
        //     isValid = false;
        //     errs[".state"] = "Please select state";
        // }
        // if (!districtId) {
        //     isValid = false;
        //     errs[".district"] = "Please select district";
        // }
       
        // if (state.emailid !== "undefined") {
        //     const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        //     if (!pattern.test(state.emailid)) {
        //       isValid = false;
        //       errs[".emailid"] = "Please enter valid email address";
        //     }
        //   }
        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    // const countryChangeHandler = (ev) => {
    //     setCountryId(ev);
    //     setStateId(null);
    //     setDistrictId(null);
    //     getStates(ev.value);
    // }
    // const stateChangeHandler = (ev) => {
    //     setStateId(ev);
    //     setDistrictId(null);
    //     getDistricts(ev.value);
    // }
    // const districtChangeHandler = (ev) => {
    //     setDistrictId(ev);
    // }

    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            fetch(
                service.ADD_UPDATE_DONOR,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        DonorId: params.id ? params.id : null,
                        FirstName: state.firstname,
                        MiddleName: state.middlename,
                        LastName: state.lastname,                        
                        Center: state.center,                        
                        Gender: gender.value,
                        PAN: state.pan,
                        CouponNo: state.couponno,
                        AddedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                        UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message.message);
                        navigate('/donor/donor');
                    }
                    else if (result.message.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message.message);
                        navigate('/donor/donor');
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
         setLoading(false);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Save Details
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <br />
                                <TableContainer component={Paper} style={{ width: '98%', alignSelf: 'center' }}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    <div style={{ fontSize: '16px', fontWeight: 'bold' }}>Donor Details</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ width: '35%' }}>
                                                    <MDInput label="First Name" fullWidth value={state.firstname || ''}
                                                        name="firstname"
                                                        onChange={changeHandler}
                                                        id="firstname"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".firstname"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Middle Name" fullWidth value={state.middlename || ''}
                                                        name="middlename"
                                                        onChange={changeHandler}
                                                        id="middlename"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".middlename"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Last Name" fullWidth value={state.lastname || ''}
                                                        name="lastname"
                                                        onChange={changeHandler}
                                                        id="lastname"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".lastname"]}</div>
                                                </TableCell>
                                            </TableRow>                                                                                      
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Center" fullWidth value={state.center || ''}
                                                        name="center"
                                                        onChange={changeHandler}
                                                        id="center"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".center"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <Dropdown menuClassName='myMenuClassName' id="gender"
                                                        name="gender"
                                                        options={genderData}
                                                        value={gender}
                                                        onChange={ev => setGender(ev)}
                                                        placeholder="Select Gender"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".gender"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="PAN" fullWidth value={state.pan || ''}
                                                        name="pan"
                                                        onChange={changeHandler}
                                                        id="pan"    
                                                        required                                                     />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".pan"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Pass No" fullWidth value={state.couponno || ''}
                                                        name="couponno"
                                                        onChange={changeHandler}
                                                        id="couponno"                                                                                                                 />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".couponno"]}</div>
                                                </TableCell>
                                                <TableCell />
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <MDButton color="info" onClick={submitHandler}>
                                            SAVE
                                        </MDButton>
                                    }
                                    {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                </MDBox>

                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default EditDonor;